import { useEffect, useState } from 'react';
import { Input } from "reactstrap";

// hooks
import { useRedux } from "../../../../hooks/index";
import { createSelector } from "reselect";

interface InputSectionProps {
  value: null | string;
  onChange: (value: string) => void;
  onEnter: () => void;
  isCatching?: boolean;
  onKeyPressEnter?: () => void;
  onKeyPressUp?: () => void;
  onKeyPressDown?: () => void;
  showAlt?: boolean;
  channelSelectedName: any;
  styleInput: any;
}

const InputSection = ({
  value,
  onChange,
  onEnter,
  isCatching,
  onKeyPressEnter,
  onKeyPressDown,
  onKeyPressUp,
  showAlt,
  channelSelectedName,
  styleInput,
}: InputSectionProps) => {

  const { useAppSelector } = useRedux();
  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      selectedConversation: state.selectedConversation,
    })
  );
  
  const { selectedConversation } = useAppSelector(errorData);

  const [id, setId] = useState<string>("");
  // Giá trị isComposing để xác định có đang trong trạng thái nhập Tiếng Việt hay không
  const [isComposing, setIsComposing] = useState(false);
  
  useEffect(() => {
    // Check nếu chuyển sang conversation khác thì clear text và auto focus input
    if (
      selectedConversation?.conversationId &&
      id !== selectedConversation?.conversationId
    ) {
      const element = document.getElementById("chat-input");
      if (element) {
        element.focus();
        setId(selectedConversation?.conversationId);
        onChange('');
      }
    }
  }, [selectedConversation?.conversationId]);

  return (
    <div className="position-relative">
      <Input
        id="chat-input"
        type="textarea"
        className="form-control form-control-lg chat-input"
        style={{
          ...styleInput,
          resize: 'none',
          outline: 'none',
          color: "#000"
        }}
        placeholder={channelSelectedName ? `Trả lời từ ${channelSelectedName}` : "Nhập nội dung tin nhắn"}
        value={value || ""}
        onChange={(e: any) => {
          // Nếu người dùng nhấn Alt (để đánh tag nhanh) hoặc nhấn Enter (để gửi luôn) thì không change value
          if (
            showAlt ||
            (
              e.nativeEvent.inputType === "insertLineBreak" &&
              e.target.value.substr(e.target.value.length - 1) === '\n'
            )
          ) {
            return;
          }
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (isComposing) {
            // Đang trong trạng thái nhập tiếng Việt, không xử lý onKeyDown
            return;
          }
          if (isCatching) {
            if (e.key === 'Enter' || e.code === 'Enter' || e.code === 'NumpadEnter') {
              e.preventDefault();
              onKeyPressEnter && onKeyPressEnter();
            } else if (e.key === 'ArrowUp') {
              e.preventDefault();
              onKeyPressUp && onKeyPressUp();
            } else if (e.key === 'ArrowDown') {
              e.preventDefault();
              onKeyPressDown && onKeyPressDown();
            }
          } else {
            if (e.key === 'Enter' || e.code === 'Enter' || e.code === 'NumpadEnter') {
              e.preventDefault();
              if (!e.shiftKey) {
                onEnter();
              } else {
                onChange(value + '\n');
              }
            }
          }
        }}
        onCompositionStart={() => setIsComposing(true)}
        onCompositionEnd={() => setIsComposing(false)}  
        onPaste={(e: any) => { // Xử lý đoạn người dùng paste vào input ở đây
          const items = e.clipboardData.items;
          let isFilePasted = false;
          for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
              e.preventDefault();
              isFilePasted = true;
              break;
            }
          }
        }}
        autoComplete="off"
      />
    </div>
  );
};
export default InputSection;
