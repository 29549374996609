import moment from "moment";
import React, { useEffect, useState } from "react";

import classnames from "classnames";
import { createSelector } from "reselect";
import { useProfile, useRedux } from "../../../hooks/index";

// api
import {
  getDetailConversation,
  getDetailConversationFb,
} from "../../../api/index";

// actions
import {
  changeReplyMsgSelected,
  onDeleteMessage,
  onSendMessage,
  updateReplyMessage,
  uploadListImageDraft
} from "../../../redux/actions";

// components
import LightBox from "../../../components/LightBox";

import { MessageOutlined, SmileOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown, Modal, notification, Tooltip } from "antd";
import Emoji from "./MessageModal/ChatInputComment/Emoji";

//images
import IconDelete from "../../../assets/images/messages/delete.svg";
import IconInbox from "../../../assets/images/messages/inbox.svg";
import IconInboxActive from "../../../assets/images/messages/inboxActive.svg";
import IconIsDeleted from "../../../assets/images/messages/isDeleted.svg";
import imagePlaceholder from "../../../assets/images/users/user-dummy-img.jpg";
import MessageTranslate from "./MessageTranslate";

// utils
import { formateDate } from "../../../utils";

// interface
import { toast } from "react-toastify";
import { updateReaction } from "../../../api/chats";
import {
  ImageTypes,
  MessagesTypes,
} from "../../../data/messages";

// styles
import styled from "styled-components";
import ChatInputComment from "./MessageModal/ChatInputComment";
import MessagesModal from "./MessageModal/MessagesModal";

interface ImageProps {
  message: MessagesTypes;
  image: any;
  onImageClick: (id: number) => void;
  index: number;
  onSetReplyData?: (reply: null | MessagesTypes | undefined) => void;
  onDeleteImg?: (imageId: string | number) => void;
}
const Image = ({ message, image, onImageClick, index, onSetReplyData, onDeleteImg }: ImageProps) => {

  return (
    <React.Fragment>
      <div className="message-img-list">
        <div>
          <a
            className="popup-img d-inline-block"
            onClick={() => onImageClick(index)}
          >
            <img src={image.url} alt="" className="rounded border" style={{ cursor: 'zoom-in', objectFit: 'cover', backgroundColor: 'white' }} />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};
interface ImagesProps {
  message: MessagesTypes,
  images: ImageTypes[];
  onSetReplyData?: (reply: null | MessagesTypes | undefined) => void;
  onDeleteImg?: (imageId: string | number) => void;
}
const Images = ({ message, images, onSetReplyData, onDeleteImg }: ImagesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(0);




  const onImageClick = (id: number) => {
    setSelected(id);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        className="message-img mb-0"
        style={{
          maxWidth: images?.length === 4 ? 264 : 400
        }}
      >
        {(images || []).map((image: ImageTypes, key: number) => (
          <Image
            message={message}
            image={image}
            key={key}
            index={key}
            onImageClick={onImageClick}
            onSetReplyData={onSetReplyData}
            onDeleteImg={onDeleteImg}
          />
        ))}
      </div>
      {isOpen && (
        <LightBox
          isOpen={isOpen}
          images={images}
          onClose={onClose}
          defaultIdx={selected}
        />
      )}
    </>
  );
};

interface AttachmentsProps {
  attachments: any;
}
const Attachments = ({ attachments }: AttachmentsProps) => {
  return (
    <div>
      {(attachments || []).map((attachment: any, key: number) => (
        <div
          key={key}
          className={classnames("p-3", "border-primary", "border rounded-3", {
            "mt-2": key !== 0,
          })}
          style={{
            maxWidth: 400,
          }}
        >
          <div className="d-flex align-items-center attached-file">
            <div className="flex-shrink-0 avatar-sm me-3 ms-0 attached-file-avatar">
              <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-20">
                <i className="ri-attachment-2"></i>
              </div>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <div className="text-start">
                <h5 className="font-size-14 mb-1" style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>{attachment.url.split('/')[attachment.url.split('/')?.length - 1]?.split('?')[0]}</h5>
                {/* <p className="text-muted text-truncate font-size-13 mb-0">
                  {attachment.desc}
                </p> */}
              </div>
            </div>
            <div className="flex-shrink-0 ms-4">
              <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
                <div>
                  <a
                    href={attachment.url ? attachment.url : "#"}
                    className="text-muted"
                    download
                  >
                    <i className="bx bxs-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

interface MessageProps {
  className?: string;
  message: any;
  selectedConversation: any;
  isFromMe: boolean;
  messagReadedInconverstaion: any;
}
const Message = ({
  className,
  message,
  selectedConversation,
  messagReadedInconverstaion,
  isFromMe,
}: MessageProps) => {
  const { dispatch, useAppSelector } = useRedux();

  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      replyMsgSelected: state.replyMsgSelected,
    })
  );
  const { replyMsgSelected } = useAppSelector(errorData);

  const chatUserprofile = selectedConversation?.from?.avatar
    ? selectedConversation?.from?.avatar
    : imagePlaceholder;
  const profile = chatUserprofile;
  const date = message.createdAt ? formateDate(message.createdAt, "HH:mm") : '';
  const [emoji, setEmoji] = useState(message?.reaction?.emoji || '');
  const [showOptionMess, setShowOptionMess] = useState(false);
  const [isModalOpenChat, setIsModalOpenChat] = useState(false);
  const [obj, setObj] = useState<any>({});

  const { userProfile } = useProfile();

  const onChangeEmoji = async (emoji: string) => {
    const payload = {
      action: 'react',
      reaction: 'other',
      emoji: emoji,
      messageId: message?.messageId,
      conversationId: selectedConversation?.conversationId
    }
    const resUpdateReaction: any = await updateReaction(payload);
    setEmoji(emoji);
    if (!resUpdateReaction?.success) {
      setEmoji('')
      toast.error(resUpdateReaction?.message || 'Có lỗi xảy ra, vui lòng thử lại sau');
    }
    const resConversation = await getDetailConversationFb({ conversationId: selectedConversation.conversationId });
    if (resConversation?.data?.updated_time) {
      selectedConversation.lastMessageAt = resConversation?.data?.updated_time;
    }
      window.postMessage({
        fb: {
          taskId: +new Date(),
          type: 'REACT_MESSAGE',
          pageId: selectedConversation.pageId,
          threadId: selectedConversation.threadKey.split('_')[1],
          conversationLastTimestamp: +new Date(selectedConversation.lastMessageAt),
          messageTimestamp: +new Date(message.createdAt),
          messageText: message.text || '',
          emoji,
          globalUserId: selectedConversation?.globalUserId,
        }
      }, '*');
    
  }
  const selectMessageToReply = () => {
    dispatch(updateReplyMessage(message));
  }
  useEffect(() => {
    setEmoji(message?.reaction?.emoji || '');
  }, [message?.reaction?.emoji])

  const onSelectReply = () => {
    if (replyMsgSelected === message?.messageId) {
      dispatch(changeReplyMsgSelected(null));
    } else {
      dispatch(changeReplyMsgSelected(message?.messageId));
    }
  };

  const onDeleteMsg = () => {
    dispatch(onDeleteMessage({
      messageId: message?.messageId,
      conversationId: selectedConversation?.conversationId
    }));
  };

  const showModalChat = async () => {
    try {
      setIsModalOpenChat(true);
      const resDetailConversation = await getDetailConversation({ conversationId: selectedConversation?.from?.customerId });
      console.log("resDetailConversation", resDetailConversation)
      setObj({ // Gửi tin nhắn cho user thì là tin nhắn inbox bình thường
        ...selectedConversation,
        channelId: selectedConversation?.from?.channelId,
        conversationId: selectedConversation?.from?.customerId,
        from: {
          ...selectedConversation?.from,
          name: selectedConversation?.from?.name,
          avatar: selectedConversation?.from?.avatar,
        },
        lastCustomerReplyAt: resDetailConversation?.data?.lastCustomerReplyAt,
        type: "INBOX",
        extraInfo: {},
      });
      dispatch(uploadListImageDraft([]));
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleOkChat = () => {
    setIsModalOpenChat(false);
    setObj({});
  };
  const handleCancelChat = () => {
    setIsModalOpenChat(false);
    setObj({});
  };

  const onSend = async (data: any, fileList: any, type: string, typeMsg: string) => {
    const id: string = obj?.conversationId;
    if ((!data?.text || data?.text?.trim()?.length == 0) && fileList.length === 0) {
      notification.error({
        message: 'Vui lòng nhập nội dung trả lời hoặc hình ảnh, file đính kèm',
        description: "",
      });
      return;
    }
    if (fileList?.length > 0) {
      const arr = [];
      // Kiểm tra nếu có text trong data
      if (data?.text?.trim()?.length > 0) {
        arr.push({ text: data.text.trim() });
      }

      // Kiểm tra nếu có file trong fileList
      if (fileList?.length > 0) {
        fileList.forEach((file: any) => {
          arr.push({ file });
        });
      }
      const formattedDate = new Date();
      const promises = arr.map((msg: any) => {
        const params: any = {
          attachments: msg?.file
            ? [
                {
                  name: msg.file.originalname,
                  type: msg.file.mimetype,
                  url: msg.file.location,
                  contentId: msg?.file.id,
                },
              ]
            : [],
          id: id,
          conversation: obj,
          conversationId: "",
          createdAt: formattedDate,
          createdBy: {},
          extraInfo: {},
          from: {
            id: obj?.channelId || "",
          },
          channelId: obj?.channelId || "",
          messageId: '',
          refParentId: typeMsg === 'REPLY_PRIVATE' ? message?.messageId : '',
          text: msg?.text || '',
          to: {
            name: obj?.from?.name
          },
          type: type,
          typeMsg: typeMsg,
          contentId: msg?.file ? msg.file.id : '',
        };
        debugger;

        // Gửi tin nhắn
        return dispatch(onSendMessage(params));
      });

      // Chờ tất cả các Promise được hoàn thành
      await Promise.all(promises);
    } else {
      // Giả lập message thay mỗi nội dung để add vào array history
      const formattedDate = new Date();
      let params: any = {
        attachments: [],
        id: id,
        conversation: obj,
        conversationId: "",
        createdAt: formattedDate,
        createdBy: {
          name: userProfile?.name,
        },
        extraInfo: {
          translation: {
            translations: [
              { language: obj?.extraInfo?.translation?.translateSentTo, message: data.textOriginal }
            ]
          }
        },
        from: {
          id: obj?.channelId || "",
        },
        channelId: obj?.channelId || "",
        messageId: '',
        refParentId: typeMsg === 'REPLY_PRIVATE' ? message?.messageId : '',
        text: data.text && data.text,
        to: {
          name: obj?.from?.name
        },
        type: type,
        typeMsg: typeMsg,
      };
      debugger;
      dispatch(onSendMessage(params));
    }
    setIsModalOpenChat(false);
  };

  const renderImgs = () => {
    return (
      <Images
        images={message?.attachments?.filter((item: any) => item?.type?.includes('image'))}
        message={message}
      />
    );
  }

  const renderVideos = () => {
    return (
      <div>
        {
          message?.attachments?.filter((item: any) => item?.type?.includes('video'))?.map((video: any, index: string) => (
            <video
              key={index}
              src={video?.url}
              width="320"
              height="240"
              controls
              style={{
                backgroundColor: '#000',
                borderRadius: 8,
              }}
            />
          ))
        }
      </div>
    );
  }

  const renderAudios = () => {
    return (
      <div>
        {
          message?.attachments?.filter((item: any) => item?.type?.includes('audio'))?.map((audio: any, index: string) => (
            <audio
              key={index}
              src={audio?.url}
              controls
              style={{
                borderRadius: 8,
              }}
            />
          ))
        }
      </div>
    );
  }

  return (
    <li
      className={classnames(
        className,
        "chat-list",
        { right: isFromMe },
      )}
    >
      <div
        className={classnames(
          "conversation-list",
          { right: isFromMe },
        )}
        onMouseEnter={() => setShowOptionMess(true)}
        onMouseLeave={() => setShowOptionMess(false)}
        style={{ flex: 1 }}
      >

        {
          isFromMe ? null : (
            <div className="chat-avatar">
              <img src={profile} alt="" />
            </div>
          )
        }
        {
          (isFromMe && showOptionMess) && (
            <div className="" style={{ marginRight: 20 }}>
              <div style={{ fontSize: 11, fontWeight: '500', color: '#495057bf' }}>{isFromMe ? message?.createdBy?.name : message?.from?.name}</div>
              {
                message?.createdAt ? (

                  <div style={{ fontSize: 11, color: '#495057bf' }}>
                    {
                      moment(message.createdAt).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') ?
                        'hôm nay' :
                        moment(message.createdAt).format('DD/MM/YYYY') == moment().subtract(1, 'days').format('DD/MM/YYYY') ?
                          'hôm qua' :
                          moment(message.createdAt).format('DD/MM/YYYY')
                    }
                  </div>
                ) : null
              }
            </div>
          )
        }
        <div className="ctext-wrap">
          <div className="ctext-wrap-content">
          
            {message.text && (
              message?.messageTags?.find((cmt: any) => message.text.includes(cmt?.name)) ? (
                <p className="mb-0 ctext-content" style={isFromMe ? { textAlign: 'left' } : {}}>
                  <span style={{
                    backgroundColor: '#cfdde6',
                    borderRadius: 4,
                    padding: '2px 5px'
                  }}>
                    {
                      message?.messageTags?.find((cmt: any) => message.text.includes(cmt?.name))?.name
                    }
                  </span>
                  {message.text?.replace(message?.messageTags?.find((cmt: any) => message.text.includes(cmt?.name))?.name, '')}
                </p>
              ) : (
                <p className="mb-0 ctext-content" style={isFromMe ? { textAlign: 'left' } : {}}>{message.text}</p>
              )
            )}
            {
              message?.attachments && message?.attachments?.length > 0 && message?.attachments?.find((item: any) => item?.type && item?.url) ? (
                <div style={{
                  padding: message.text ? '8px 0' : 0,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8
                }}>
                  {
                    message?.attachments?.filter((item: any) => item?.type?.includes('image'))?.length > 0 ? renderImgs() : null
                  }
                  {
                    message?.attachments?.filter((item: any) => item?.type?.includes('video'))?.length > 0 ? renderVideos() : null
                  }
                  {
                    message?.attachments?.filter((item: any) => item?.type?.includes('audio'))?.length > 0 ? renderAudios() : null
                  }
                  {
                    message?.attachments?.filter((item: any) =>
                      !item?.type?.includes('image') &&
                      !item?.type?.includes('video') &&
                      !item?.type?.includes('audio')
                    )?.length > 0 ? (
                      <Attachments
                        attachments={
                          message?.attachments?.filter((item: any) =>
                            !item?.type?.includes('image') &&
                            !item?.type?.includes('video') &&
                            !item?.type?.includes('audio')
                          )
                        }
                      />
                    ) : null
                  }
                </div>
              ) : null
            }
            <MessageTranslate
              message={message}
              isFromMe={isFromMe}
            />

            <div className="time-action">
              {isFromMe ? (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}>
                  {
                    message?.type === 'COMMENT' ? (
                      <Tooltip
                        title={
                          message?.isRemoved ?
                            "Bình luận đã được xóa" :
                            "Xóa bình luận"
                        }
                      >
                        <div
                          className="cmt-action"
                          onClick={() => {
                            if (!message?.isRemoved) {
                              onDeleteMsg();
                            }
                          }}
                          style={{ cursor: message?.isRemoved ? 'not-allowed' : 'pointer' }}
                        >
                          <img
                            src={message?.isRemoved ? IconIsDeleted : IconDelete}
                            alt=""
                            style={{ width: 15, height: 15 }}
                          />
                        </div>
                      </Tooltip>
                    ) : null
                  }
                  <div className="time-info" style={{ marginLeft: 4 }}>
                    {date}
                  </div>
                </div>
              ) : (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}>
                  <div className="time-info" style={{ marginRight: 4 }}>
                    {date}
                  </div>
                  {
                    message?.type === 'COMMENT' ? (
                      <>
                        <Tooltip
                          title={"Nhắn tin đến khách hàng"}
                        >
                          <div
                            className="cmt-action"
                            onClick={() => {
                              if (!message?.isRemoved) {
                                showModalChat()
                              }
                            }}
                            style={{ cursor: message?.isRemoved ? 'not-allowed' : 'pointer' }}
                          >
                            <img
                              src={message?.repliedPrivateMessageId ? IconInboxActive : IconInbox}
                              alt=""
                              style={{ width: 15, height: 15 }}
                            />
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={
                            message?.isRemoved ?
                              "Bình luận đã được xóa" :
                              "Xóa bình luận"
                          }
                        >
                          <div
                            className="cmt-action"
                            onClick={() => {
                              if (!message?.isRemoved) {
                                onDeleteMsg();
                              }
                            }}
                            style={{ cursor: message?.isRemoved ? 'not-allowed' : 'pointer' }}
                          >
                            <img
                              src={message?.isRemoved ? IconIsDeleted : IconDelete}
                              alt=""
                              style={{ width: 15, height: 15 }}
                            />
                          </div>
                        </Tooltip>

                      </>
                    ) : null
                  }
                </div>
              )}
            </div>
            {
              emoji && <div className="reacted">{emoji}</div>
            }
            {
              isFromMe && messagReadedInconverstaion?.messageId == message?.messageId ? (
                <div>
                  <img src={profile} alt="" style={{width: 20, height: 20, borderRadius: 10}}/>
                </div>
              ) : null
            }
          </div>
         
        </div>
        {
          (!isFromMe && showOptionMess) && (
            <div
              className="btn-option-mes">
              <div className="d-flex align-items-center">
                <div className="reacttion-btn">
                  <Dropdown
                    trigger={['click']}
                    placement="bottom"
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    dropdownRender={() => <Emoji onChange={onChangeEmoji} />}
                  >
                    <div className="btn_record">
                      <SmileOutlined
                        style={{ fontSize: 15, cursor: 'pointer' }}
                      />
                    </div>
                  </Dropdown>
                </div>

                <div className="reacttion-btn">
                  <Tooltip placement="top" title={"Trả lời"}>
                    <div className="btn_record" onClick={selectMessageToReply}>
                      <MessageOutlined
                        style={{ fontSize: 15, cursor: 'pointer' }}
                      />
                    </div>
                  </Tooltip>
                </div>

                <div className="d-flex flex-column">
                  <div style={{ fontSize: 11, fontWeight: '500', color: '#495057bf' }}>{isFromMe ? message?.createdBy?.name : message?.from?.name}</div>
                  {
                    message?.createdAt ? (

                      <div style={{ fontSize: 11, color: '#495057bf' }}>
                        {
                          moment(message.createdAt).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') ?
                            'hôm nay' :
                            moment(message.createdAt).format('DD/MM/YYYY') == moment().subtract(1, 'days').format('DD/MM/YYYY') ?
                              'hôm qua' :
                              moment(message.createdAt).format('DD/MM/YYYY')
                        }
                      </div>
                    ) : null
                  }
                </div>
              </div>
              {
                message?.type === 'COMMENT' ? (
                  <Checkbox
                    onChange={onSelectReply}
                    checked={replyMsgSelected === message?.messageId}
                    style={{
                      margin: '8px 0 0 9px',
                    }}
                  >
                    <span style={{ fontSize: 10, color: 'red' }}>
                      Chọn trả lời
                    </span>
                  </Checkbox>
                ) : null
              }
            </div>
          )
        }
      </div>
      {
        isModalOpenChat && obj?.channelId ? (
          <Modal
            title={<div style={{ margin: -10, fontSize: 15 }}>
              <div style={{display:'flex'}}><i style={{ fontSize: 24, color: "#000" }} className="bx bxs-envelope"></i> &nbsp;<div style={{ fontSize: 14}}>Tin nhắn mới</div></div>
              <hr style={{ margin: "1rem -15px" }} />
              <div style={{ color: "rgb(136, 136, 136)", fontWeight: 500, margin: "-5px 0px 20px 0px" }}> Tới&nbsp;
                <span style={{ color: "#de343b", fontWeight: 400, padding: "2px 8px", border: '1px solid rosybrown', borderRadius: 2, backgroundColor: 'floralwhite', cursor: 'pointer' }}>{obj?.from?.name}</span>
              </div>
            </div>}
            footer={null}
            open={isModalOpenChat}
            onOk={handleOkChat}
            onCancel={handleCancelChat}
            width={600}
          >
            <div >
              <MessagesModal
                objConversation={obj}
                onSend={onSend}
                repliedPrivateMessageId={message?.repliedPrivateMessageId}

              />
              {/* <ChatInputComment
                onSend={onSend}
                objConversation={obj}
                repliedPrivateMessageId={message?.repliedPrivateMessageId}
              /> */}
            </div>
          </Modal>
        ) : null
      }
      
    </li>
  );
};

export default styled(Message)`
  .time-action {
    margin-top: 4px;
  }
  .cmt-action {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1px;
    border-radius: 2px;
    &:hover {
      border: 1px solid gray;
    }
  }
  .time-info {
    color: #495057bf;
    font-size: 11px;
    font-weight: 500;
  }
  .send_to {
    color: #de343b;
    font-weight: 400;
    padding: 2px 8px;
    border: 1px solid rosybrown;
    border-radius: 2px;
    background-color: floralwhite;
    cursor: pointer;
  }
  .ant-modal-content {
    padding: 0px 0px !important;
    margin: 0px 0px !important;
}
`
