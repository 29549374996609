import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { toast } from "react-toastify";

// components
import QuickReplies from './QuickReplies';
import LightBox from "../../../../../components/LightBox";
import ModalAddImage from "../../../../../components/ModalAddImage";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import type { GetProp, UploadFile, UploadProps } from 'antd';
import {
  Dropdown,
  message,
  notification,
  Spin,
  Upload,
  Tooltip,
  Space,
  Radio,
  Tag,
} from 'antd';
import {
  PictureFilled,
  PlusOutlined,
  SmileOutlined,
  CloseOutlined,
  MailOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import IconInfo from "../../../../../assets/images/ic_info.svg";
import IconUp from "../../../../../assets/images/ic_up.svg";
import IconDown from "../../../../../assets/images/ic_down.svg";
import IconDel from "../../../../../assets/images/ic_del.svg";
import IconQuickMsg from "../../../../../assets/images/ic_quickMsg.svg";

// hooks
import { useRedux } from "../../../../../hooks/index";
import { createSelector } from "reselect";

// interface
import {
  uploadMedia,
  getPageSelected,
  getQuickRepliesList,
  onTranslateText,
} from "../../../../../api/index";

// actions
import {
  uploadListImageDraft,
} from "../../../../../redux/actions";

// utils
import {
  checkKeyInsert,
} from "../../../../../utils";

// styles
import styled from "styled-components";
import ListImageDraft from "./ListImageDraft";
import Emoji from "./Emoji";


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface IndexProps {
  onSend: (data: any, fileList: any, type: string, typeMsg: string) => void;
  objConversation: any;
  repliedPrivateMessageId: string;
  className?: string;
  msgList: any;
}
const Index = ({
  onSend,
  objConversation,
  repliedPrivateMessageId,
  className,
  msgList,
}: IndexProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const [channelSelected, setChannelSelected] = useState<any>(null);
  const [fileList, setFileList] = useState<any>([]);

  /*
  input text
  */
  const [text, setText] = useState<null | string>("");
  const [loadingTranslate, setLoadingTranslate] = useState<boolean>(false);
  const [textOriginal, setTextOriginal] = useState<null | string>("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [modalAddImage, setModalAddImage] = useState<boolean>(false);
  const [position, setPosition] = useState(repliedPrivateMessageId ? 'REPLY_INBOX' : 'REPLY_PRIVATE');
  const [quickRepliesList, setQuickRepliesList] = useState<any>(null);
  const [quickListFinded, setQuickListFinded] = useState<any>(null);
  const [quickSelected, setQuickSelected] = useState<any>(null);
  const [showSuggest, setShowSuggest] = useState<boolean>(false);

  const onChangeText = (value: string) => {
    setText(value);
    if (position === 'REPLY_PRIVATE') {
      setFileList([]);
    }
    if (value?.charAt(0) === '/') {
      const arrFinded = quickRepliesList?.items?.filter((item: any) =>
        item?.shortcut?.toLowerCase().includes(value?.replace('/', '')?.toLowerCase()) ||
        item?.messages?.filter((message: any) => message?.message?.toLowerCase().includes(value?.replace('/', '')?.toLowerCase())).length > 0
      );
      if (arrFinded?.length > 0) {
        setShowSuggest(true);
        setQuickListFinded(arrFinded);
        setQuickSelected(arrFinded[0]);
      } else {
        setShowSuggest(false);
        setQuickListFinded(null);
        setQuickSelected(null);
      }
    } else {
      setShowSuggest(false);
      setQuickListFinded(null);
      setQuickSelected(null);
    }
  };

  const handleKeyPressUp = () => {
    if (quickListFinded?.length > 0) {
      const index = quickListFinded.findIndex((item: any) => item?.id === quickSelected?.id);
      if (index === 0) {
        setQuickSelected(quickListFinded[quickListFinded.length - 1]);
      } else {
        setQuickSelected(quickListFinded[index - 1]);
      }
    }
  }

  const handleKeyPressDown = () => {
    if (quickListFinded?.length > 0) {
      const index = quickListFinded.findIndex((item: any) => item?.id === quickSelected?.id);
      if (index === quickListFinded.length - 1) {
        setQuickSelected(quickListFinded[0]);
      } else {
        setQuickSelected(quickListFinded[index + 1]);
      }
    }
  }

  const onChangeEmoji = (value: string) => {
    setText(text + value);
    if (position === 'REPLY_PRIVATE') {
      setFileList([]);
    }
  }

  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      listImageDraft: state.listImageDraft,
      selectedConversation: state.selectedConversation,
    })
  );
  // Inside your component
  const { listImageDraft, selectedConversation } = useAppSelector(errorData);

  const onFillQuick = () => {
    setText(quickSelected?.messages?.map((item: any) => checkKeyInsert(item?.message, selectedConversation, channelSelected)).join(' '));
    setFileList(quickSelected?.messages?.map((item: any) => item?.photos).flat().concat(quickSelected?.messages?.map((item: any) => item?.files).flat()));
    setShowSuggest(false);
    setQuickListFinded(null);
    setQuickSelected(null);
  }

  const onSendQuick = () => {
    let data: any = {};
    let arr: any = [];
    data["text"] = quickSelected?.messages?.map((item: any) => checkKeyInsert(item?.message, selectedConversation, channelSelected)).join(' ');
    data["textOriginal"] = textOriginal;
    onSend(
      data,
      [
        ...quickSelected?.messages?.map((item: any) => item?.photos).flat().concat(quickSelected?.messages?.map((item: any) => item?.files).flat()),
        ...listImageDraft,
        ...arr
      ],
      objConversation?.type,
      position
    );
    dispatch(uploadListImageDraft([]));
    setShowSuggest(false);
    setQuickListFinded(null);
    setQuickSelected(null);
    setText("");
    setFileList([]);
  }
  
  const getDetailChannel = async (data: any) => {
    try {
      const response: any = await getPageSelected({ channelIds: data?.channelIds });
      if (response?.data?.items?.length > 0) {
        setChannelSelected(response?.data?.items?.[0]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const getQuickRepliesListData = async (data: any) => {
    try {
        const response: any = await getQuickRepliesList({ channelId: data.channelId, page: data?.page, limit: data?.limit });
        if (response?.data?.items) {
            const arr = quickRepliesList?.items && data.page !== 1 ? [...quickRepliesList?.items, ...response.data.items] : response.data.items;
            setQuickRepliesList({
                total: response.data.total,
                page: data.page,
                items: arr,
            });
        }
    } catch (error) {
        console.log("error", error);
    }
  }
  
  const onTranslate = async () => {
    if (selectedConversation?.extraInfo?.translation?.translateSentTo) {
      const payload = {
        message: text,
        language: selectedConversation?.extraInfo?.translation?.translateSentTo,
      }
      setTextOriginal(text);
      setLoadingTranslate(true);
      const res: any = await onTranslateText(payload);
      setLoadingTranslate(false);
      if (res?.success) {
        setText(res?.data?.translated?.translated || '');
      }
    } else {
      notification.warning({ description: "Chưa chọn ngôn ngữ dịch", message: "Thông báo" })
    }

  }

  // Call đến Api channels cùng channelIds để get chi tiết 1 channel
  // -> hiển thị avatar & tên channel ở textbox gửi tin nhắn
  useEffect(() => {
    if (objConversation?.channelId) {
      getDetailChannel({
        channelIds: objConversation?.channelId,
      });
      getQuickRepliesListData({
        channelId: selectedConversation?.channelId || '',
        page: 1,
        limit: 50,
      });
    }
  }, [objConversation?.channelId]);

  // Submit Message
  const onSubmit = async () => {
    let data: any = {};
    let arr: any = [];
    data["text"] = text;
    data["textOriginal"] = textOriginal;
    onSend(data, [...fileList, ...listImageDraft, ...arr], objConversation?.type, position);
    dispatch(uploadListImageDraft([]));
    setText("");
    setFileList([]);
  };

  return (
    <div className={className} style={{position: 'relative'}}>
      <div className="chat-input-section" style={{ backgroundColor: '#fff', borderRadius: '0 0 10px 10px' }}>
        {
          showSuggest ? (
            <div className="suggestion-box">
              <div className="suggestion-box-header">
                <div className="suggestion-box-info">
                  <img
                    src={IconInfo}
                    alt=""
                    style={{
                      width: 16,
                      height: 16,
                    }}
                  />
                  <span style={{ fontSize: 13 }}>Nhấn <strong>Enter</strong> hoặc <strong>Click</strong> chuột để chọn mẫu câu, nhấn phím <img src={IconDown} style={{ width: 16, height: 16, verticalAlign: 'sub' }} /> <img src={IconUp} style={{ width: 16, height: 16, verticalAlign: 'sub' }} /> để điều hướng</span>
                </div>
                <div
                  className="suggestion-box-close"
                  onClick={() => setShowSuggest(false)}
                >
                  <img
                    src={IconDel}
                    style={{
                      width: 18,
                      height: 18
                    }}
                  />
                </div>
              </div>
              <ul className="suggestions-list">
                {
                  quickListFinded?.map((quick: any) => (
                    <li
                      className="suggestion-element"
                      style={{
                        backgroundColor: quickSelected?.id === quick?.id ? '#D2EBFF' : ''
                      }}
                      key={quick?.id}
                    >
                      <div
                        style={{
                          padding: '8px 16px',
                        }}
                        onClick={onFillQuick}
                      >
                        <div className="suggestion-content">
                          <div style={{
                            minWidth: 40
                          }}>
                            <span style={{
                              fontSize: 13
                            }}>
                              <div className="suggestion-header-section">
                                <img
                                  src={IconQuickMsg}
                                  alt=""
                                  style={{
                                    width: 18,
                                    height: 18,
                                  }}
                                />
                                <Tag className="shortcut-tag">{quick?.shortcut}</Tag>
                              </div>
                              {quick?.messages?.map((item: any) => item?.message).join(' ')}
                            </span>
                          </div>
                          {
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              {
                                quick?.messages?.map((item: any) => item?.photos)?.flat()?.length > 0 ? (
                                  <div style={{
                                    position: 'relative',
                                    width: 40,
                                    height: 40,
                                    marginRight: 8,
                                    borderRadius: 6,
                                  }}>
                                    <img src={quick?.messages?.map((item: any) => item?.photos)?.flat()[0]?.location} style={{
                                      width: 40,
                                      height: 40,
                                      borderRadius: 6,
                                    }} />
                                    {
                                      quick?.messages?.map((item: any) => item?.photos)?.flat()?.length > 1 ? (
                                        <Tooltip title={(
                                          <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}>
                                            {
                                              quick?.messages?.map((item: any) => item?.photos)?.flat()?.map((photo: any, index: number) => (
                                                <img
                                                  key={photo?.id}
                                                  src={photo?.location}
                                                  style={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: 6,
                                                    marginLeft: index == 0 ? 0 : 8,
                                                  }}
                                                />
                                              ))

                                            }
                                          </div>
                                        )}>
                                          <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: 40,
                                            height: 40,
                                            marginRight: 8,
                                            borderRadius: 6,
                                            backgroundColor: '#0000004d',
                                          }}>
                                            <span style={{ color: 'white' }}>+{quick?.messages?.map((item: any) => item?.photos)?.flat()?.length - 1}</span>
                                          </div>
                                        </Tooltip>
                                      ) : null
                                    }
                                  </div>
                                ) : null
                              }
                              {
                                quick?.messages?.map((item: any) => item?.files)?.flat()?.length > 0 ? (
                                  <Tooltip title={
                                    quick?.messages?.map((item: any) => item?.files)?.flat()?.map((photo: any, index: number) => (
                                      <div key={photo?.id}>{photo?.originalname}</div>
                                    ))
                                  }>
                                    <div style={{
                                      width: 14,
                                      height: 14,
                                      position: 'relative',
                                      marginRight: 16,
                                    }}>
                                      <PaperClipOutlined
                                        style={{ fontSize: 18 }}
                                      />
                                      <div style={{
                                        fontSize: quick?.messages?.map((item: any) => item?.files)?.flat()?.length > 99 ? 7 : 10,
                                        position: 'absolute',
                                        top: -6,
                                        right: -12,
                                        width: 16,
                                        height: 16,
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontWeight: 'bold',
                                        border: '1px solid white',
                                      }}>
                                        {
                                          quick?.messages?.map((item: any) => item?.files)?.flat()?.length > 99 ?
                                            "99+" :
                                            quick?.messages?.map((item: any) => item?.files)?.flat()?.length
                                        }
                                      </div>
                                    </div>
                                  </Tooltip>
                                ) : null
                              }
                            </div>
                          }
                        </div>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>
          ) : null
        }
        <ListImageDraft />
        <div className="px-3 p-lg-2">
          <Form
            id="chatinput-form"
            onSubmit={(e: any) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="row g-0 align-items-center" style={{ position: 'relative' }}>
              <div className="col input-chat" style={{ position: 'relative' }}>
                <InputSection
                  value={text}
                  onChange={onChangeText}
                  onEnter={onSubmit}
                  isCatching={showSuggest}
                  onKeyPressEnter={onSendQuick}
                  onKeyPressUp={handleKeyPressUp}
                  onKeyPressDown={handleKeyPressDown}
                />
                {
                  channelSelected ? (
                    <Tooltip title={channelSelected?.name}>
                      <img
                        className="avatar"
                        src={channelSelected?.picture}
                        alt=""
                      />
                    </Tooltip>
                  ) : null
                }
                <div className="btn_translate" onClick={onTranslate}>{loadingTranslate ? <Spin /> : "Dịch"}</div>
              </div>
              <div
                // className="col-auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 0px 10px"
                }}
              >
                <div style={{ width: '70%', fontSize: 12, color: 'rgb(136, 136, 136)', }}>
                  <Space>
                    <Radio.Group value={position} onChange={(e) => setPosition(e.target.value)}>
                      <Radio.Button disabled={!msgList?.items || msgList?.items?.length === 0}  value="REPLY_INBOX" style={{ paddingInline: 6, lineHeight: "20px", height: 'auto' }}><MailOutlined /></Radio.Button>
                      <Radio.Button disabled={repliedPrivateMessageId?.length > 0} value="REPLY_PRIVATE" style={{ paddingInline: 6, lineHeight: "20px", height: 'auto' }}><MessageOutlined /></Radio.Button>
                    </Radio.Group>
                  </Space>&nbsp;{position === "REPLY_PRIVATE" ?
                    <span>Gửi tin nhắn từ bình luận <Tooltip title={"Bạn chỉ có thể gửi tin nhắn văn bản hoặc một ảnh"} >
                      <QuestionCircleOutlined style={{ color: 'rgb(250, 173, 20)' }} /></Tooltip >
                    </span> :
                    <span>Gửi trực tiếp đến hộp thư </span>}
                </div>
                <div style={{
                  display: 'flex', alignItems: 'center', right: 0,
                  position: 'absolute'
                }}>
                  <Dropdown
                    trigger={['click']}
                    placement="topRight"
                    getPopupContainer={(trigger: any) => trigger.parentNode}
                    dropdownRender={() => <Emoji onChange={onChangeEmoji} />}
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <Tooltip title={'Emoji'}>
                      <div className="btn_record">
                        <SmileOutlined
                          style={{ fontSize: 20, color: '#adb5c2' }}
                        />

                      </div>
                    </Tooltip>
                  </Dropdown>
                  {
                    (!text || position !== 'REPLY_PRIVATE') ? (
                      <Tooltip title={'Image'}>
                        <div
                          className="btn_chat"
                          onClick={() => setModalAddImage(true)}
                        >
                          <PictureFilled
                            style={{ fontSize: 20, fill: '#adb5c2', color: '#adb5c2' }}
                          />
                          {
                            fileList?.filter((item: any) => item?.mimetype?.includes('image')).length > 0 ? (
                              <>
                                <div className="badge" style={{
                                  fontSize: fileList?.filter((item: any) => item?.mimetype?.includes('image')).length > 99 ? 7 : 10,
                                }}>
                                  {
                                    fileList?.filter((item: any) => item?.mimetype?.includes('image')).length > 99 ?
                                      "99+" :
                                      fileList?.filter((item: any) => item?.mimetype?.includes('image')).length
                                  }
                                </div>
                                <div
                                  className="badge-delete"
                                  onClick={() => {
                                    setFileList(fileList?.filter((item: any) => !item?.mimetype?.includes('image')));
                                  }}
                                >
                                  <CloseOutlined style={{ fontSize: 10, color: 'white' }} />
                                </div>
                              </>
                            ) : null
                          }
                        </div>
                      </Tooltip>
                    ) : null
                  }
                  <QuickReplies
                    selectedConversation={objConversation}
                    channelSelected={channelSelected}
                    onSetTxt={setText}
                    onSetFileList={setFileList}
                  />
                  <EndButtons onSubmit={onSubmit} />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {previewOpen && (
        <LightBox
          isOpen={previewOpen}
          images={[{ url: previewImage }]}
          onClose={() => {
            setPreviewOpen(false);
            setPreviewImage('');
          }}
          defaultIdx={0}
        />
      )}
      {modalAddImage && (
        <ModalAddImage
          isOpen={modalAddImage}
          fileLists={fileList?.filter((item: any) => item?.mimetype?.includes('image'))}
          idItem={'0'}
          channelId={objConversation?.channelId}
          onClose={() => setModalAddImage(false)}
          onSetFileList={(data: any) => {
            setFileList((currentFileList: any) => {
              return data?.concat(currentFileList);
            });
          }}
          onReplaceFileList={(list: any) => {
            setFileList(fileList?.filter((item: any) => !item?.mimetype?.includes('image')).concat(list));
          }}
          onlyOne={position === 'REPLY_PRIVATE'}
        />
      )}
    </div>
  );
};

export default styled(Index)`
  .tag-select {
    .ant-select-selector {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
    }
  }
  .btn_translate {
    position: absolute;
    right: 0px;
    top: 10px;
    cursor: pointer;
    color: #007bff;
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    &:hover {
      text-decoration: underline;
    }
  }
  .avatar {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
  }
  .chat-input {
    padding: 10px 70px 10px 40px;
    border:none;
  }
  .btn_record {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 8px;
  }
  .badge {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid white;
  }
  .badge-delete {
    position: absolute;
    top: -9px;
    right: -9px;
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
    color: white;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 1px solid white;
  }
  .btn_chat {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      .badge-delete {
          display: flex;
      }
    }
  }
  .icon_record {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 50px;
    background-color: red;
  }
  .btn_cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: white;
    width: 80px;
    height: 32px;
    font-weight: 500;
    margin-right: 10px;
    cursor: pointer;
  }
  .btn_send {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: blue;
    width: 80px;
    height: 32px;
    font-weight: 500;
    color: white;
    cursor: pointer;
  }
  .txt_time {
    text-align: center;
    color: white;
    font-weight: 500;
    margin-top: 10px;
  }
  .suggestion-box {
    position: absolute;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 0;
    background-color: #F2F4F7;
    width: 100%;
    max-height: calc(min(60vh, 465px));
    border-radius: 8px;
    overflow: hidden;
    z-index: 2000;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
    top: 0px;
    transform: translateY(calc(-100%));
    .suggestion-box-header {
      padding: 8px 12px;
      background-color: #FFFFFF;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      border-bottom: 1px solid #EAECF0;
      color: #344054;
      .suggestion-box-info {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 4px;
      }
      .suggestion-box-close {
        border-radius: 6px;
        padding: 3px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          background-color: #EFECF0;
        }
      }
    }
    .suggestions-list {
      overflow-y: scroll;
      padding: 0;
      margin: 0;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      .suggestion-element {
        list-style: none;
        cursor: pointer;
        overflow: hidden;
        flex-shrink: 0;
        background-color: #FFFFFF;
        border-width: 0px 0px 1px 0px;
        border-style: solid;
        border-color: #EAECF0;
        &:hover {
          background-color: #EAECF0;
        }
        .suggestion-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          .suggestion-header-section {
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            gap: 8px;
            vertical-align: -4.5px;
            height: 22px;
            .shortcut-tag {
              font-weight: 600;
              color: black;
              background-color: #F9FAFB;
              border-color: #D0D5DD;
              padding: 0 4px;
              border-radius: 4px;
              line-height: 18px;
              height: 20px;
            }
          }
        }
      }
    }
  }
`
