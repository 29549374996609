import React, { useEffect, useState } from "react";
import _ from 'lodash';

// router
import { useSearchParams } from "react-router-dom";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";

// actions
import {
  getConversationList,
  changeSelectedChat,
  changeChannelSelected,
  changeConversationSelected,
  onSearch,
} from "../../../redux/actions";

// component
import {
  Spin
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import InfiniteScroll from "react-infinite-scroll-component";
import AppSimpleBar from "../../../components/AppSimpleBar";
import ChatUser from "./ChatUser";

interface DirectMessagesProps {
  selectedChat: string | number;
  onSelectConversation: (obj: any) => void;
  itemTagSelected: any,
  fillter: any
}
const ConversationList = ({
  selectedChat,
  onSelectConversation,
  itemTagSelected,
  fillter
}: DirectMessagesProps) => {
  const [searchParams] = useSearchParams();
  const { dispatch, useAppSelector } = useRedux();
  const groupId = searchParams.get('groupId');
  const channelId = searchParams.get('channelId');

  const stateData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      conversationList: state.conversationList,
      refreshConversation: state.refreshConversation,
      groupInfo: state.groupInfo,
      isSearchConversation: state.isSearchConversation,
    })
  );
  // Inside your component
  const { conversationList, refreshConversation, groupInfo, isSearchConversation} = useAppSelector(stateData);
  useEffect(() => {
    if ((groupId && groupInfo?.channelIds) || channelId) {
      dispatch(changeSelectedChat(null));
      dispatch(changeConversationSelected(null));
      dispatch(changeChannelSelected(groupId ? groupInfo?.channelIds?.toString() : channelId));
      if(fillter?.search || fillter?.tags?.length > 0 
        || fillter?.exceptTags?.length > 0 
        || fillter?.isSpam === true
        || fillter?.startTime || fillter?.endTime
      ) {
        dispatch(onSearch(true))
      } else {
        dispatch(onSearch(false))
      }
      dispatch(getConversationList({
        channelId: groupId ? groupInfo?.channelIds?.toString() : channelId,
        page: 1,
        limit: 50,
        tags: fillter?.tags?.includes("all") ? [] : fillter?.tags,
        isReaded: fillter?.isRead,
        isSpam: fillter?.isSpam,
        startTime: fillter?.startTime,
        endTime: fillter?.endTime,
        search: fillter?.search,
        exceptTags: fillter?.exceptTags,
        tagFilterMode: fillter?.tagFilterMode,
        timeFilterMode: fillter?.timeFilterMode,
        type: fillter?.type,
      }));
    }
  }, [groupInfo?.channelIds, groupId, channelId, itemTagSelected, fillter?.isRead, fillter?.isSpam, refreshConversation, fillter?.tags, fillter?.exceptTags, fillter?.tagFilterMode, fillter?.timeFilterMode, fillter?.startTime, fillter?.endTime, fillter?.search, fillter?.type]);
  // const sortedConversations = _.orderBy(conversationList?.items, ['readed'], ['asc']); 

  const fetchMoreData = () => {
    if (conversationList?.total === conversationList?.items?.length) {
      return;
    }
    setTimeout(() => {
      dispatch(getConversationList({
        channelId: groupId ? groupInfo?.channelIds?.toString() : channelId,
        page: conversationList?.page + 1,
        limit: 50,
        hasMore: true,
        tags: fillter?.tags?.includes("all") ? [] : fillter?.tags,
        isReaded: fillter?.isRead,
        isSpam: fillter?.isSpam,
        startTime: fillter?.startTime,
        endTime: fillter?.endTime,
        search: fillter?.search,
        exceptTags: fillter?.exceptTags,
        tagFilterMode: fillter?.tagFilterMode,
        timeFilterMode: fillter?.timeFilterMode,
        type: fillter?.type,
      }));
    }, 300);
  };


  const sortedConversations = _.orderBy(
        conversationList?.items,
        ['readed', 'lastMessageAt'],
        ['asc', 'desc']
      );
  return (
    <AppSimpleBar>
      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          <InfiniteScroll
            dataLength={sortedConversations?.length}
            next={fetchMoreData}
            hasMore={conversationList?.total > conversationList?.items?.length}
            loader={sortedConversations?.length > 0 &&
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 30
              }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            }
            height={'calc(100vh - 190px)'}
          >
            {sortedConversations?.length > 0 && sortedConversations?.map((user: any, key: number) => (
              <ChatUser
                user={user}
                // key={sortedConversations?.[key]?.conversationId ? sortedConversations?.[key]?.conversationId : key}
                key={key}
                selectedChat={selectedChat}
                onSelectConversation={onSelectConversation}
              />
            ))}
          </InfiniteScroll>
        </ul>
      </div>
    </AppSimpleBar>
  );
};

export default ConversationList;
