import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';


// router
// component
import { Table, Input, Row, Col, notification, Select, Button, Spin, Space, Modal } from 'antd';


import styled from "styled-components";
import PopUpEditUser from "./PopUpEditUser";
import { getListUsers, updateUser } from "../../api/users";
import { toast } from "react-toastify";

interface IndexProps {
  className?: string;
}

const Index = (props: IndexProps) => {

  const [data, setData] = useState<any>([]);
  const [customerId, setCustomerId] = useState<any>('')
  const [channelId, setChannelId] = useState<any>('')
  const [showModalEdit, setShowModelEdit] = useState(false)
  const [itemSelected, setItemSelected] = useState(null)

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState('');


  const getData = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page,
      limit
    }
    setData([])
    const res: any = await getListUsers(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }


  const handleChangePage = (evt: number, size: number) => {
    onSearch(evt, size);
  };

  const onUpdate = async (values: any) => {
    const res: any = await updateUser(values);
    if (res?.success) {
      setShowModelEdit(false)
      setItemSelected(null)
      getData(1, 10)
      toast.success('Cập nhật thành công')
    } else {
      toast.error(res?.data?.message ||  'Lỗi vui lòng thử lại')
    }

  }

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`;
  };

  const onSearch = async (page: number, limit: number) => {
    setLoading(true)
    const data = {
      page: page,
      limit: limit,
      q: q,

    }
    setData([])
    const res: any = await getListUsers(data);
    setLoading(false)
    if (res?.success) {
      setData(res.data.items)
      setTotal(res.data.total)
    } else {
      notification.error({ message: 'Lỗi vui lòng thử lại' });
    }
  }


  useEffect(() => {
    getData(1, 10);
  }, []);

  const columns = [
    {
      title: 'Tên người dùng',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (value: any, item: any) => (
        <div className="pb-2">
          <img
            src={item?.avatar}
            alt="avatar"
            style={{ width: 25, height: 25, marginRight: 8, borderRadius: 5 }}
          />
          {item?.name || ''}
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      width: 150,
      render: (item: any) => (
        <div>
          {item ? 'Hoạt động' : 'Không hoạt động'}
        </div>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '',
      width: 100,
      render: (value: any, item: any) => (
        <Space size="middle">
          <div onClick={() => {
            setShowModelEdit(true)
            setItemSelected(item)
          }} style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }} >
            <EditOutlined />
          </div>
          {/* <div onClick={() => console.log('Edit clicked')} style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }} >
            <DeleteOutlined />
          </div> */}
        </Space>
      ),
    },

  ];

  return (
    <div className={props.className}>
      <div className="p-4">
        <div className="filtter">
          <div className="title_page">Quản lý người dùng</div>
          <Row gutter={12}>
            <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
              <Input allowClear onChange={(e) => { setQ(e.target.value) }} placeholder="Tìm kiếm" />
              <div className="d-flex justify-content-end m-2">
                <div className="d-flex justify-content-end"><Button onClick={() => onSearch(1, 10)} color="primary">Tìm kiếm</Button></div>
              </div>
            </Col>
            <Col span={6}>

            </Col>
            <Col span={6}>

            </Col>
            <Col span={6}>
             
            </Col>
          </Row>
        </div>
        <Table
          loading={loading}
          dataSource={data} columns={columns}
          pagination={{
            showSizeChanger: true,
            total: total,
            onChange: handleChangePage,
            showTotal: showTotal,
          }}
          scroll={{ x: 800, y: 'calc(100vh - 356px)' }}
        />
      </div>
      <Modal destroyOnClose title="Chỉnh sửa người dùng" open={showModalEdit} footer={null} onCancel={() => { setShowModelEdit(false) }}>
        <PopUpEditUser itemSelected={itemSelected} onUpdate={onUpdate} />
      </Modal>
    </div>
  );
};

export default styled(Index)`
  width: 100%;
  overflow: hidden;
  .filtter {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  .title_page {
    margin-bottom: 15px;
    font-weight: 600;
  }

`