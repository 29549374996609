export enum ChatsActionTypes {
  API_RESPONSE_SUCCESS = "@@chats/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@chats/API_RESPONSE_ERROR",

  GET_FAVOURITES = "@@chats/GET_FAVOURITES",
  GET_DIRECT_MESSAGES = "@@chats/GET_DIRECT_MESSAGES",
  GET_CHANNELS = "@@chats/GET_CHANNELS",

  ADD_CONTACTS = "@@chats/ADD_CONTACTS",
  CREATE_CHANNEL = "@@chats/CREATE_CHANNEL",
  CHANGE_SELECTED_CHAT = "@@chats/CHANGE_SELECTED_CHAT",
  CHANGE_REPLY_MSG_SELECTED = "@@chats/CHANGE_REPLY_MSG_SELECTED",
  ADD_TAG_GROUP_SUCCESS = "@@chats/ADD_TAG_GROUP_SUCCESS",
  CHANGE_CHANNEL_SELECTED = "@@chats/CHANGE_CHANNEL_SELECTED",
  CHANGE_CONVERSATION_SELECTED = "@@chats/CHANGE_CONVERSATION_SELECTED",
  CHANGE_CONVERSATION_FILTER_STATE = "@@chats/CHANGE_CONVERSATION_FILTER_STATE",
  
  CHANGE_SELECTED_CONVERSATION = "@@chats/CHANGE_SELECTED_CONVERSATION",
  CHANGE_IS_LOAD_CONVERSATION = "@@chats/CHANGE_IS_LOAD_CONVERSATION",
  CHANGE_GROUP_INFO = "@@chats/CHANGE_GROUP_INFO",
  CHANGE_PAGE_INFO = "@@chats/CHANGE_PAGE_INFO",
  GET_CHAT_USER_DETAILS = "@@chats/GET_CHAT_USER_DETAILS",
  GET_CHAT_USER_CONVERSATIONS = "@@chats/GET_CHAT_USER_CONVERSATIONS",
  TOGGLE_USER_DETAILS_TAB = "@@chats/TOGGLE_USER_DETAILS_TAB",

  // MESSAGE
  INIT_MESSAGES_HISTORIES = "@@chats/INIT_MESSAGES_HISTORIES",
  GET_MESSAGES_HISTORIES = "@@chats/GET_MESSAGES_HISTORIES",
  UPDATE_TAG_CONVERSATION = "@@chats/UPDATE_TAG_CONVERSATION",
  GET_CONVERSATION_LIST = "@@chats/GET_CONVERSATION_LIST",
  UPDATE_CONVERSATION_LIST = "@@chats/UPDATE_CONVERSATION_LIST",
  UPDATE_TAGS_IN_CONVERSATION = "@@chats/UPDATE_TAGS_IN_CONVERSATION",
  UPDATE_CONVERSATION_SELECT = "@@chats/UPDATE_CONVERSATION_SELECT",
  ON_SEND_MESSAGE = "@@chats/ON_SEND_MESSAGE",
  ON_SEND_MULTI_MESSAGE = "@@chats/ON_SEND_MULTI_MESSAGE",
  ON_SEND_ONE_MESSAGE = "@@chats/ON_SEND_ONE_MESSAGE",
  ON_DELETE_MESSAGE = "@@chats/ON_DELETE_MESSAGE",
  RECEIVE_MESSAGE = "@@chats/RECEIVE_MESSAGE",
  READ_MESSAGE = "@@chats/READ_MESSAGE",
  RECEIVE_MESSAGE_FROM_USER = "@@chats/RECEIVE_MESSAGE_FROM_USER",
  DELETE_MESSAGE = "@@chats/DELETE_MESSAGE",
  FORWARD_MESSAGE = "@@chats/FORWARD_MESSAGE",
  DELETE_USER_MESSAGES = "@@chats/DELETE_USER_MESSAGES",
  GET_CHANNEL_DETAILS = "@@chats/GET_CHANNEL_DETAILS",
  TOGGLE_FAVOURITE_CONTACT = "@@chats/TOGGLE_FAVOURITE_CONTACT",
  GET_ARCHIVE_CONTACT = "@@chats/GET_ARCHIVE_CONTACT",
  TOGGLE_ARCHIVE_CONTACT = "@@chats/TOGGLE_ARCHIVE_CONTACT",
  READ_CONVERSATION = "@@chats/READ_CONVERSATION",
  DELETE_IMAGE = "@@chats/DELETE_IMAGE",
  REPLY_IMAGE = "@@chats/REPLY_IMAGE",

  UPDATE_LIST_IMAGE_DRAFT= "@@chats/UPDATE_LIST_IMAGE_DRAFT",
  UPDATE_TAG_LIST= "@@chats/UPDATE_TAG_LIST",
  REFRESH_CONVERSATION_LIST= "@@chats/REFRESH_CONVERSATION_LIST",
  MESSAGE_USER_READED= "@@chats/MESSAGE_USER_READED",
  RECEIVE_REACTION= "@@chats/RECEIVE_REACTION",
  UPDATE_REPLY_MESSAGE= "@@chats/UPDATE_REPLY_MESSAGE",
  ON_SEND_MESSAGE_BY_EXTENSION= "@@chats/ON_SEND_MESSAGE_BY_EXTENSION",
  ON_HANDLE_STATUS_EXTENSION= "@@chats/ON_HANDLE_STATUS_EXTENSION",
  ON_HANDLE_STATUS_EXTENSION_TRANSLATE= "@@chats/ON_HANDLE_STATUS_EXTENSION_TRANSLATE",
  ONSEARCH_CONVERSATION= "@@chats/ONSEARCH_CONVERSATION",
  ONTRANSLATIONMESSAGEID= "@@chats/ONTRANSLATIONMESSAGEID",
  ON_UPDATE_TRANSLATION_SUCCESS= "@@chats/ON_UPDATE_TRANSLATION_SUCCESS",
  ON_CHANGE_TEXT_TRANSLATE_INPUT= "@@chats/ON_CHANGE_TEXT_TRANSLATE_INPUT",

}

export interface ConversationFilterState {
  isRead?: boolean,
  isSpam?: boolean,
  tags?: any[],
  startTime?: string;
  endTime?: string;
  timeFilterMode ?: string;
  type?: string;
}





export interface ChatsState {
  favourites: Array<any>;
  directMessages: Array<any>;
  channels: Array<any>;
  selectedChat: string | number | null;
  replyMsgSelected: string | number | null;
  channelSelected: string | number | null;
  selectedConversation: object;
  groupInfo: object;
  pageInfo: object;
  chatUserDetails: object;
  chatUserConversations: {};
  messagesHistories: any;
  conversationList: any;
  isOpenUserDetails: boolean;
  channelDetails: object;
  archiveContacts: Array<any>;
  conversationSelected: any,
  listImageDraft: Array<any>;
  tagList: Array<any>;
  refreshConversation: any;
  messagReadedInconverstaion: any;
  isAddTagGroupSuccess: boolean;
  conversationFilterState: ConversationFilterState;
  replyMessage: any;
  extensionReady: boolean;
  isLoadingConversationList: boolean;
  isSearchConversation: boolean;
  updatingTagConversation: boolean;
  sendingMessage: boolean;
  textTranslateInput: object | null;
  extensionReadyTranslate: boolean;
}
