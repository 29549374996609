import React, { useEffect, useRef, useState } from "react";
import _, { set } from 'lodash';

import { hexToRgba } from "../../utils/color";

import { Row, Col, notification, Button, Select } from 'antd';

import styled from "styled-components";
import SelectBase from "../../components/SelectBase";
import { CHANNELS } from "../../api/urls";

interface FormAssignPageProps {
  className?: string;
  itemSelected?: any;
  onSave?: any;
  channels?: any;

}

const FormAssignPage = (props: FormAssignPageProps) => {
  const {itemSelected, onSave, channels} = props;

  const [memberSelected, setMemberSelected] = useState<any>([])
  const [channelListSelected, setChannelListSelected] = useState<any>([])

  const [listPageWithAll, setListPageWithAll] =useState<any>([])
  

  const onSubmit = () => {
    if(memberSelected.length == 0)  {
      notification.warning({ message: 'Vui lòng chọn thành viên' });
      return
    }
    if(channelListSelected.length == 0)  { 
      notification.warning({ message: 'Vui lòng chọn page' });
      return
    }
    const data = {
      memberSelected,
      channelListSelected,
    }
    onSave(data)
  }

  const onChangeSelectPage = (items: any) => {
    if(items.find((x: any) => x.key === "all")) {
      setChannelListSelected(listPageWithAll)
    } else {
      setChannelListSelected(items)
    }
  }


  useEffect(() => {
    setListPageWithAll([{id: "all", value: "Tất cả", name: "Tất cả"},...channels,].map((item: any) => ({
      key: item.id,
      value: item.name,
      picture: item.picture,
      ...item,
    })))
  }, [channels])

  useEffect(() => {
    if(itemSelected) { // case edit
      setMemberSelected({
        id: itemSelected?.user?.id,
        key: itemSelected?.user?.id,
        value: itemSelected?.user?.name,
        role: itemSelected?.role,
      })

      if(itemSelected.channels?.length === channels?.length) { // Thêm tất cả
        setChannelListSelected([{id: "all", value: "Tất cả", name: "Tất cả"}, ...itemSelected.channels]?.map((item: any) => ({
          key: item.id,
          value: item.name,
        })))
      } else {
        setChannelListSelected(itemSelected.channels?.map((item: any) => ({
          key: item.id,
          value: item.name,
        })))
      }
      
    }

  }, [itemSelected, channels]);

  console.log("channelListSelected", channelListSelected)
  return (
    <div className={props.className}>

      <div>
          <div className="p-2">
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <div>Thành viên</div>
                <SelectBase
                  labelInValue
                  value={memberSelected}
                  onChange={(values: any, items: any) => {
                    setMemberSelected({
                      ...items?.option,
                      ...values,
                    })
                  }}
                  endpoint={"/users?role=User"}
                  style={{ width: '100%' }}
                  placeholder="Thành viên"
                  optionRenderer={(option: any) => (
                    <div className="d-flex align-items-center">
                      <img style={{width: 20, height: 20, borderRadius: 100, marginRight: 10}} className="item_page" src={option?.avatar} alt="option" />
                      <div className="item_label_page" style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> {option.name}</div>
                    </div>
                  )}
                />
              </Col>
              
              <Col span={16}>
                <div>Chỉ định page</div>
                <Select
                  showSearch
                  options={listPageWithAll}
                  labelInValue
                  value={channelListSelected}
                  onChange={onChangeSelectPage}
                  // onSelect={onSelectPage}
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Chọn Page"
                  optionRender={(option: any) => (
                    <div className="d-flex">
                      {
                        option.key === "all" ? null : (
                          <img style={{width: 20, height: 20, borderRadius: 100, marginRight: 10}} className="item_page" src={option?.data?.picture} alt="option?.data" />
                        )
                      }
                      <div className="item_label_page" style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}> {option?.data?.name}</div>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </div>
    
      </div>


      <div className="d-flex justify-content-center pt-3" style={{ position: 'absolute', bottom: 10, left: 0, right: 0, margin: '0 auto', background: '#fff' }}>
        <Button onClick={onSubmit} type="primary">
          Lưu
        </Button>
      </div>
 
    </div>
  );
};

export default styled(FormAssignPage)`
  width: 100%;
  overflow: hidden;
  min-height: 70vh;
  .title_page {
    margin-bottom: 15px;
    font-weight: 600;
  }
  .list_page {
    display: flex;
    flex-wrap: wrap;
  }
  .channel-item {
 
  }
  .channel-item:hover {
    background-color: ${hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3)};
  }
  .channel-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .item_page {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 8px;
  }
  .item_label_page {
    display: grid;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`