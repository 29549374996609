import { takeEvery, fork, put, all, call , select, take, actionChannel, ActionPattern } from "redux-saga/effects";
import { v4 as uuidv4 } from 'uuid';

// Login Redux States
import { ChatsActionTypes } from "./types";
import { changeIsLoadConversation, chatsApiResponseSuccess, chatsApiResponseError, changeSelectedConversation, onReadedMess, updateReplyMessage, onSendMessageByExtension, onSendOneMessWithFile  } from "./actions";

import {
  getChannels as getChannelsApi,
  createChannel as createChannelApi,
  getChatUserDetails as getChatUserDetailsApi,
  getChatUserConversations as getChatUserConversationsApi,
  sendMessage,
  onDeleteMessage as deleteMessageApi,
  receiveMessage as receiveMessageApi,
  readMessage as readMessageApi,
  getChannelDetails as getChannelDetailsApi,
  toggleFavouriteContact as toggleFavouriteContactApi,
  getArchiveContact as getArchiveContactApi,
  toggleArchiveContact as toggleArchiveContactApi,
  readConversation as readConversationApi,
  deleteImage as deleteImageApi,
  getMessageList,
  getConversationList as getConversationListApi,
  onUpdateTranslationMessageId,
  getDetailConversation,
  updateMessIdToBE,
  addTagForConversation,
  getContentFbIds,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

//actions
import {
  getDirectMessages as getDirectMessagesAction,
  getFavourites as getFavouritesAction,
  getChannels as getChannelsAction,
} from "./actions";
import { selectorsExtensionReady, selectorsGetConversationList, selectorsGetMessagesHistories, selectorsGetReplyMessage, selectorsGetSelectedChat, selectorsGetSelectedConversation, selectorsIsSearchConversation } from "./selectors";
import { toast } from "react-toastify";
import { notification, message } from 'antd';
import { isWithin24Hours } from "../../utils/dateutils";


function sendMessageExtensions(data: any) {
  // Gửi message đến extension để Gửi tin nhắn
  try {
    const files: any =  data.files || [];
    const attachments: any =  data.attachments || [];
    const replyMessage: any = {};

    if (data.replyMessage) {
      replyMessage.message = data.replyMessage.text;
      replyMessage.timestamp = +new Date(data.replyMessage.createdAt);
    }
        window.postMessage({
          fb: {
            taskId: +new Date(),
            type: 'SEND_MESSAGE',
            pageId: data.conversation.pageId,
            threadId: data?.conversation?.threadKey && data?.conversation?.threadKey?.split('_')?.length > 0 ?
              data?.conversation?.threadKey?.split('_')[1] :
              '',
            time: +new Date(data.conversation.lastMessageAt),
            message: data.text || '',
            files,
            attachments,
            psid: data?.conversation?.from?.customerId && data?.conversation?.from?.customerId?.split('_')?.length > 0 ?
              data?.conversation?.from?.customerId.split('_')[1] :
              '',
            replyMessage: replyMessage.timestamp ? replyMessage : undefined,
            messageIdExtention: data.messageIdExtention,
            globalUserId: data?.conversation?.globalUserId,
          }
        }, '*');
  } catch (error) {
  console.log("🚀 ~ function*sendMessageExtensions ~ error:", error)
  }
}


function onTranslationListMessage(listMessageNotTransalte: any, conversationId: string) {
  try {
    if (listMessageNotTransalte?.length > 0) {
      window.postMessage({
        fb: {
          type: 'TRANSLATE_LIST',
          conversationId: conversationId,
          messages: listMessageNotTransalte,
        }
      }, '*');
    }

  } catch (error: any) {
   
  }
}



function* getChannels() {
  try {
    const response: Promise<any> = yield call(getChannelsApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNELS, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_CHANNELS, error));
  }
}


function* createChannel({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(createChannelApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_CHANNEL, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.CREATE_CHANNEL, error));
  }
}

function* getChatUserDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_DETAILS, error)
    );
  }
}

function* getChatUserConversations({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserConversationsApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, error)
    );
  }
}

function* getMessagesHistories({ payload: data }: any): Generator<any, void, any>  {
  try {
    const response: Promise<any> = yield call(getMessageList, data.id, {page: data.page, limit: data.limit});
    const responseData = yield response;

    const responseDataHashMap = responseData?.data?.items.reduce((map: any, message: any, index: number) => {
      map[message.messageId] = { ...message, originalIndex: index };
      return map;
    }, {});


    const messagesHistories = yield select(selectorsGetMessagesHistories);
    let messagesHistoriesHashMap: { [key: string]: any } = {};
    if(data?.page === 1 && messagesHistories?.items) { 
      messagesHistoriesHashMap = responseDataHashMap;
    } else {
      messagesHistoriesHashMap = {
        ...messagesHistories?.items,
        ...responseDataHashMap,
      }
    }

    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          total: responseData.data?.total,
          page: data?.page,
          items: messagesHistoriesHashMap,
        },
      )
    );

    // Xử lý dịch message 
    const selectedConversation = yield select(selectorsGetSelectedConversation);
    let lastSeenMessage: any = null;
    if(responseData?.data?.items && selectedConversation?.extraInfo?.translation?.translateReceivedTo) { // Phải có ngôn ngữ dịch đến
      let listMessageNotTransalte: any = [];
      responseData?.data?.items.forEach((element: any) => {
        // Thêm bước message đó đã được dịch chưa
        if(element?.text && !element?.extraInfo?.translation?.translations?.find((item: any) => item.language === selectedConversation?.extraInfo?.translation?.translateReceivedTo)) {
          listMessageNotTransalte.push({
            from: '',
            to: selectedConversation?.extraInfo?.translation?.translateReceivedTo,
            messageId: element?.messageId,
            text: element?.text,
          })
        }

        // Xử lý thêm message đã đọc 
        if(element.seen === true && element?.from?.id === element?.channelId) {
          lastSeenMessage = element;
        }
      });
      const extensionReady = yield select(selectorsExtensionReady)
      if(extensionReady ) {
        onTranslationListMessage(listMessageNotTransalte, selectedConversation?.conversationId);
      } else {
        toast.error("Bạn chưa cài đủ extension, vui lòng cài kiểm tra đã cài đủ 2 extension chưa")
      }
    }

    // Xử lý message đã đọc
    if(lastSeenMessage && lastSeenMessage?.messageId) {
      yield put(onReadedMess({
        channelId: lastSeenMessage?.channelId,
        conversationId: lastSeenMessage?.conversationId,
        messageId: lastSeenMessage?.messageId,
        text: lastSeenMessage?.text,
      }));
    }


  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_MESSAGES_HISTORIES, error)
    );
  }
}

function* updateTagConversation({ payload: data }: any): Generator<any, void, any>  {
  try {
    // Add dữ liệu tag mới vào redux trước khi call api để đánh tag sáng ngay
    const conversationList = yield select(selectorsGetConversationList);
    const selectedConversation = yield select(selectorsGetSelectedConversation);
    const existingIndex = conversationList?.items?.findIndex((item: any) => item?.conversationId === data?.conversationId);

    if (existingIndex >= 0) {
      const updatedItems = updateConversationTags(conversationList.items, data);
      // Chỉ cập nhật nếu có thay đổi
      if (JSON.stringify(updatedItems) !== JSON.stringify(conversationList.items)) {
        yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CONVERSATION_LIST, {
          total: conversationList.total,
          page: conversationList.page,
          items: updatedItems,
        }));
      }
      // Cập nhật tags trong input chat nếu conversation được chọn trùng với conversation đang cập nhật
      if (selectedConversation?.conversationId === data?.conversationId) {
        yield put(changeSelectedConversation({
          ...selectedConversation,
          lastSeen: selectedConversation?.lastSeen || null,
          tags: [...data?.tags],
        }));
      }
    }
    const response: Promise<any> = yield call(addTagForConversation, data.conversationId, {tags: data.tags?.map((_item: any) => _item?.id)});
    const responseData = yield response;
    if (responseData?.success) {
      yield put(
        chatsApiResponseSuccess(ChatsActionTypes.UPDATE_TAG_CONVERSATION, responseData)
      );
    } else {
      // Nếu api lỗi thì clear tag mới trong redux đi
      if (existingIndex >= 0) {
        yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CONVERSATION_LIST, {
          total: conversationList.total,
          page: conversationList.page,
          items: conversationList.items,
        }));
        // Cập nhật tags trong input chat nếu conversation được chọn trùng với conversation đang cập nhật
        if (selectedConversation?.conversationId === data?.conversationId) {
          yield put(changeSelectedConversation({
            ...selectedConversation,
            lastSeen: selectedConversation?.lastSeen || null,
            tags: selectedConversation?.tags,
          }));
        }
      }
      yield put(
        chatsApiResponseError(ChatsActionTypes.UPDATE_TAG_CONVERSATION, responseData)
      );
      toast.error(responseData?.data?.message?.error || responseData?.data?.message || 'Có lỗi xảy ra');
    }
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.UPDATE_TAG_CONVERSATION, error)
    );
  }
}

function* onSendMultiMsg({ payload: data }: any): Generator<any, void, any> { // Gửi nhiều ảnh qua extension
  try {
    const response: Promise<any> = yield call(
      getContentFbIds,
      data?.conversation?.conversationId,
      {
        contentIds: data.attachments?.map((_item: any) => _item?.contentId)
      }
    );
    const responseData = yield response;
    if (responseData?.success) {
      const selectedConversation = yield select(selectorsGetSelectedConversation)
      const messagesHistories = yield select(selectorsGetMessagesHistories)
      let messagesHistoriesNew = messagesHistories.items;
      const dataNew = {
        ...data,
        messageIdExtention: uuidv4(),
        files: responseData?.data?.contentFbIds
      }
      if (selectedConversation?.conversationId === data?.conversation?.conversationId) {
        messagesHistories.items[dataNew.messageIdExtention] = dataNew; // add tạm message giả
  
        yield put(chatsApiResponseSuccess(
          ChatsActionTypes.GET_MESSAGES_HISTORIES,
          {
            items: messagesHistoriesNew,
            total: messagesHistories.total,
            page: messagesHistories.page,
          }
        ))
      }
      yield put(
        chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_MULTI_MESSAGE, responseData)
      );
      const lastMessage = Object.values(messagesHistories?.items).reduce((prev: any, current: any) => {
        if (!current?.messageId) {
          return prev;
        }
        return (prev?.originalIndex > current?.originalIndex) ? prev : current;
      }, {});
  
   
      if (lastMessage && typeof lastMessage === 'object' && 'createdAt' in lastMessage) {
        dataNew.conversation.lastMessageAt = lastMessage.createdAt
      }
      
      sendMessageExtensions(dataNew);
    } else {
      yield put(
        chatsApiResponseError(ChatsActionTypes.ON_SEND_MULTI_MESSAGE, responseData)
      );
      toast.error(responseData?.data?.message?.error || responseData?.data?.message || 'Có lỗi xảy ra');
    }
  } catch (error: any) {
    notification.error({
      message: "Lỗi",
      description: error,
      duration: 6,
    });
  }
}

function* onSendMessage({ payload: data }: any): Generator<any, void, any> {
  try {
    const extensionReady = yield select(selectorsExtensionReady)
    const selectedConversation = yield select(selectorsGetSelectedConversation)
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    let dataNew = {
      ...data,
      messageIdExtention: uuidv4(),
    }

    if (selectedConversation?.conversationId === data?.conversation?.conversationId) {
      messagesHistories.items[dataNew.messageIdExtention] = dataNew; // add tạm message giả
      console.log('A00', messagesHistories.items)
      yield put(chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          items: messagesHistories.items,
          total: messagesHistories.total,
          page: messagesHistories.page,
        }
      ))
    }
    const replyMessage = yield select(selectorsGetReplyMessage);

    let response: any;
    // Gửi luồng thường
    if(data?.type === "COMMENT" 
      || // Case gửi tin nhắn thường
      (selectedConversation?.lastCustomerReplyAt && isWithin24Hours(selectedConversation?.lastCustomerReplyAt) && !replyMessage?.messageId)
      || // Case gửi tin nhắn khi trả lời comment
      ((data?.typeMsg === "REPLY_INBOX") && data?.conversation?.lastCustomerReplyAt && isWithin24Hours(data?.conversation?.lastCustomerReplyAt) && !replyMessage?.messageId)
      || // Case gửi reply private khi trả lời comment
      (data?.typeMsg === "REPLY_PRIVATE")
    ) {
      response = yield call(
        sendMessage,
        data.id,
        {
          action: data.typeMsg,
          message: data.text,
          contentId: data.contentId,
          replyToMessageId: data.refParentId,
        }
      );
      if(response?.success)  {
         //Call api thành công tin nhắn trong ngày
        // Update lại messageId khi gửi message thành công
        if (selectedConversation?.conversationId === data?.conversation?.conversationId) { // update lại message id khi gửi message thành công
          const messNew = { ...data, messageId: response?.data.message_id, };
          // xóa tin nhắn giả đi
          // Add tin nhắn success khi call api vào list message
          delete messagesHistories.items[dataNew.messageIdExtention];
          messagesHistories.items[response?.data.message_id] = messNew;
          yield put(chatsApiResponseSuccess(
            ChatsActionTypes.GET_MESSAGES_HISTORIES,
            {
              items: messagesHistories.items,
              total: messagesHistories.total,
              page: messagesHistories.page,
            }
          ))

          // xử lý dịch tin nhắn khi send
          const dataTranslate = {
            from: '',
            to: selectedConversation?.extraInfo?.translation?.translateReceivedTo,
            messageId: response?.data.message_id,
            text: data.text,
          }
          window.postMessage({
            fb: {
              type: 'TRANSLATE_LIST',
              conversationId: selectedConversation?.conversationId,
              messages: [dataTranslate],
            }
          }, '*');

        } else if (
          selectedConversation?.conversationId !== data?.conversation?.conversationId &&
          data?.to?.name && data?.typeMsg
        ) {  // Case gửi show popup tin nhắn mới khi trả lời bình luận nhưng chưa check case quá 24h có thể phải gửi qua extension
          messagesHistories.items[data?.refParentId] = { // Thêm repliedPrivateMessageId để sáng cái button trả lời bình luận
            ...messagesHistories.items[data?.refParentId],
            repliedPrivateMessageId: response?.data.message_id,
          }
          yield put(chatsApiResponseSuccess(
            ChatsActionTypes.GET_MESSAGES_HISTORIES,
            {
              items: messagesHistories?.items,
              total: messagesHistories.total,
              page: messagesHistories.page,
            }
          ))
          message.success(`Bạn vừa gửi tin nhắn tới ${data?.to?.name}`)
        }

        // Update converstaion list khi gửi message đi
        const conversationList = yield select(selectorsGetConversationList)
        const updatedConversationList = conversationList.items.map((conversation: any) => {
          if (conversation?.conversationId === data?.conversation?.conversationId) {
            return {
              ...conversation,
              snippet: data?.text,
              lastMessageAt: new Date().toISOString(),
            };
          }
        
          return conversation;
        });

        yield put(
          chatsApiResponseSuccess(
            ChatsActionTypes.GET_CONVERSATION_LIST,
            {
              total: conversationList.total,
              page: conversationList.page,
              items: updatedConversationList,
            },
          )
        );
      } else {
        if(response?.data?.errorCode === 'MESSAGE_IS_BEING_SENT_OUTSIDE_THE_ALLOWED_WINDOW' || response?.data?.errorCode === '6132018342') { 
           // Gửi qua extension copy code ở dưới (đoạn copy)
            if (extensionReady) {
              if(dataNew?.attachments?.length > 0) { // Nếu có file quá 24h phải lấy contentsIds
                yield put(
                  onSendOneMessWithFile(ChatsActionTypes.ON_SEND_ONE_MESSAGE, ) // add loading when send one file in message
                );
                const response: Promise<any> = yield call(
                  getContentFbIds,
                  data?.conversation?.conversationId,
                  {
                    contentIds: data.attachments?.map((_item: any) => _item?.contentId)
                  }
                );
                const responseData = yield response;
                if (responseData?.success) { 
                  dataNew = {
                    ...dataNew,
                    files: responseData?.data?.contentFbIds
                  }
                  yield put(
                    chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_ONE_MESSAGE, responseData)
                  );
                } else {
                  yield put(
                    chatsApiResponseError(ChatsActionTypes.ON_SEND_ONE_MESSAGE, responseData)
                  );
                  toast.error(responseData?.data?.message?.error || responseData?.data?.message || 'Có lỗi xảy ra');
                  return;
                }

              }
              const lastMessage = Object.values(messagesHistories?.items).reduce((prev: any, current: any) => {
                if (!current?.messageId) {
                  return prev;
                }
                return (prev?.originalIndex > current?.originalIndex) ? prev : current;
              }, {});
              if (lastMessage && typeof lastMessage === 'object' && 'createdAt' in lastMessage) {
                dataNew.conversation.lastMessageAt = lastMessage.createdAt
              }
              if (replyMessage) {
                dataNew.replyMessage = replyMessage;
              }
              sendMessageExtensions(dataNew);
              yield put(updateReplyMessage({}))
            } else {
              notification.error({
                message: "Thông báo",
                description: "Bạn chưa cài đủ extension! Hãy cài bản mới nhất",
                duration: 6,
              });
            }
          return;
        } 
        
        else {
          notification.error({
            message: "Lỗi gửi tin nhắn qua api",
            description: response?.data?.message || '',
            duration: 6,
          });
        }
      }
    } else {
      // Gửi qua extension (đoạn cần copy)
      if (extensionReady) {
        if(dataNew?.attachments?.length > 0) { // Nếu có file quá 24h phải lấy contentsIds
          yield put(
            onSendOneMessWithFile(ChatsActionTypes.ON_SEND_ONE_MESSAGE, ) // add loading when send one file in message
          );
          const response: Promise<any> = yield call(
            getContentFbIds,
            data?.conversation?.conversationId,
            {
              contentIds: data.attachments?.map((_item: any) => _item?.contentId)
            }
          );
          const responseData = yield response;
          if (responseData?.success) { 
            dataNew = {
              ...dataNew,
              files: responseData?.data?.contentFbIds
            }
            yield put(
              chatsApiResponseSuccess(ChatsActionTypes.ON_SEND_ONE_MESSAGE, responseData)
            );
          } else {
            yield put(
              chatsApiResponseError(ChatsActionTypes.ON_SEND_ONE_MESSAGE, responseData)
            );
            toast.error(responseData?.data?.message?.error || responseData?.data?.message || 'Có lỗi xảy ra');
            return;
          }

        }
        const lastMessage = Object.values(messagesHistories?.items).reduce((prev: any, current: any) => {
          if (!current?.messageId) {
            return prev;
          }
          return (prev?.originalIndex > current?.originalIndex) ? prev : current;
        }, {});
        if (lastMessage && typeof lastMessage === 'object' && 'createdAt' in lastMessage) {
          dataNew.conversation.lastMessageAt = lastMessage.createdAt
        }
        if (replyMessage) {
          dataNew.replyMessage = replyMessage;
        }
        sendMessageExtensions(dataNew);
        yield put(updateReplyMessage({}))
      } else {
        notification.error({
          message: "Thông báo",
          description: "Bạn chưa cài extension! Hãy cài bản mới nhất",
          duration: 6,
        });
      }
    }
  } catch (error: any) {
    notification.error({
      message: "Lỗi",
      description: error,
      duration: 6,
    });
  }
}

function* onDeleteMessage({ payload: data }: any): Generator<any, void, any> {
  try {
    const response: any = yield call(deleteMessageApi, data?.conversationId, data?.messageId);
    if(!response?.success) {
      notification.error({
        message: "Lỗi",
        description: response?.data?.message || '',
        duration: 6,
      });
    } else {
        // Update comment về đã xóa khi xóa comment thành công
        const messagesHistories = yield select(selectorsGetMessagesHistories)
        let messagesHistoriesNew = messagesHistories.items;
    
        messagesHistoriesNew[data?.messageId] = {
          ...messagesHistoriesNew[data?.messageId],
          isRemoved: true,
        }
        yield put(chatsApiResponseSuccess(
          ChatsActionTypes.GET_MESSAGES_HISTORIES,
          {
            items: messagesHistoriesNew,
            total: messagesHistories.total,
            page: messagesHistories.page,
          }
        ))
    } 

  } catch (error: any) {
    notification.error({
      message: "Lỗi",
      description: error,
      duration: 6,
    });
  }
}
function* getConversationList({ payload: data }: any): Generator<any, void, any> {
  try {
    if (!data?.hasMore) {
      yield put(changeIsLoadConversation(true));
    }
    const response: Promise<any> = yield call(getConversationListApi, data);
    const responseData = yield response;
    const conversationList = yield select(selectorsGetConversationList);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CONVERSATION_LIST,
        {
          total: responseData.data.total,
          page: data.page,
          items: conversationList?.items && data.page !== 1 ? [...conversationList?.items, ...responseData.data.items] : responseData.data.items,
        },
      )
    );
    if (!data?.hasMore) {
      yield put(changeIsLoadConversation(false));
    }
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CONVERSATION_LIST, error)
    );
    if (!data?.hasMore) {
      yield put(changeIsLoadConversation(false));
    }
  }
}

function* onUpdateTagsInConversation({ payload: data }: any): Generator<any, void, any> {
  try {
    const conversationList = yield select(selectorsGetConversationList);
    const existingConversation = conversationList?.items?.find((item: any) => item?.conversationId === data?.conversationId);

    if (
      existingConversation &&
      existingConversation?.tags?.length !== data?.tags?.length
    ) {
      const updatedItems = updateConversationTags(conversationList.items, data);
      yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CONVERSATION_LIST, {
        total: conversationList.total,
        page: conversationList.page,
        items: updatedItems,
      }));

      const selectedConversation = yield select(selectorsGetSelectedConversation);
      // Cập nhật tags trong input chat nếu conversation được chọn trùng với conversation đang cập nhật
      if (
        selectedConversation?.conversationId === data?.conversationId
      ) {
        yield put(changeSelectedConversation({
          ...selectedConversation,
          lastSeen: selectedConversation?.lastSeen || null,
          tags: [...data?.tags],
        }));
      }
    } else {
      console.warn("Conversation not found for ID:", data?.conversationId);
    }
  } catch (error: any) {
    console.error("Error in onUpdateTagsInConversation:", error);
  }
}

// Hàm cập nhật tags cho conversation
function updateConversationTags(items: any[], data: any) {
  return items.map((item) => {
    if (item?.conversationId === data?.conversationId) {
      return {
        ...item,
        tags: [...data?.tags],
      };
    }
    return item;
  });
}

function* onReceiveMessage({ payload: data }: any): Generator<any, void, any>  {
  try {
    // Update converstaion list
    const conversationList = yield select(selectorsGetConversationList)
    const selectedChat = yield select(selectorsGetSelectedChat);
    const isSearchConversation = yield select(selectorsIsSearchConversation);

    let conversationListNew  = conversationList.items;
    const existingIndex = conversationListNew?.findIndex((item: any) => item?.conversationId === data?.element?.conversation?.conversationId);
    if (existingIndex !== -1) { // Case đã có conversation và update conversation đó
      const existingItem = conversationListNew?.splice(existingIndex, 1)[0];
      conversationListNew.unshift({ // Cập nhật lại conversation khi nhận socket
        ...existingItem,
        snippet: data.element.conversation.snippet,
        unreadCount: selectedChat == data?.element?.conversation?.conversationId ? 0 : data.element?.conversation?.unreadCount, // nếu ở converstaion đó đang active thì ko cộng unread
        readed: data?.isPageToUser ? true : false, 
        lastMessageAt: data?.element?.conversation?.lastMessageAt,
        lastMessageFrom: data?.element?.conversation?.lastMessageFrom,
        from: {
          ...data?.element?.conversation?.from,
          avatar: data?.element?.conversation?.from?.avatar,
        },
      });
      
      if(selectedChat == data?.element?.conversation?.conversationId && !data?.isPageToUser) { // xử lý case khi nhận tin nhắn từ khách cập nhật lại trường lastCustomerReplyAt đùng để send qua extension hay là gì
        const selectedConversation = yield select(selectorsGetSelectedConversation);
        yield put(changeSelectedConversation({
          ...selectedConversation,
          lastSeen: selectedConversation?.lastSeen || null,
          lastCustomerReplyAt: data?.element?.conversation?.lastCustomerReplyAt
        }));      }
    } else { // Nếu conversation đó chưa có thì cộng vào array
      if(isSearchConversation) {
      } else {
        conversationListNew.unshift(data.element.conversation);
      }
    }
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CONVERSATION_LIST,
        {
          total: conversationList.total,
          page: conversationList.page,
          items: conversationListNew,
        },
      )
    );
    // End update converstaion list
    


    // Only update message history when user inbox
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    if(selectedChat == data?.element?.conversation?.conversationId) {

      let messagesHistoriesNew = messagesHistories.items;
      // Nếu là page gửi cho user và đã tồn tại trong list message thì ko add vào nữa
      if(data?.isPageToUser && messagesHistories.items?.[data?.element?.messageId]) {
        return;
      }

      // Case với extension : nếu message đã tồn tại trong list message thì remove message đó đi ở dưới sẽ lấy lại message từ socket, vì id Extension message giả sẽ ko đúng
      const existingMessageIndex = Object.values(messagesHistoriesNew).findIndex(
        (message: any) => (message.timestamp === new Date(data?.element?.createdAt).getTime()) && (message?.text === data?.element?.text)
      );

      if (existingMessageIndex !== -1) { // Remove message id extension giả đi
        const existingMessageKey = Object.keys(messagesHistoriesNew)[existingMessageIndex];
        // // Nhận globalUserId từ extension send mess result => Gửi vào api mark send
        // const globalUserId = messagesHistoriesNew[existingMessageKey]?.globalUserId;
        delete messagesHistoriesNew[existingMessageKey];
        // Xử lý update lại messId cho BE khi gửi message qua extension
          const payload = {
            messageId: data?.element?.messageId,
            conversationId: data?.element?.conversation?.conversationId,
            // globalUserId: globalUserId,
          }
          yield call(updateMessIdToBE, payload);

      }


      // Xử lý dịch message khi nhận tin nhắn từ event
      if(data.element?.messageId) {
        const selectedConversation = yield select(selectorsGetSelectedConversation);
        const listMessageNotTransalte = [
          {
            from: '',
            to: selectedConversation?.extraInfo?.translation?.translateReceivedTo,
            messageId: data.element?.messageId,
            text: data.element?.text,
          }
        ]
        onTranslationListMessage(listMessageNotTransalte, selectedChat);
      } else {
        notification.error({ description: "Không có message id", message: "Lỗi", duration: 6 })
      }

    
      if(data?.element) {
        messagesHistoriesNew[data?.element?.messageId] = {
          ...data?.element,
        }
      }
      
      yield put(chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          items: messagesHistoriesNew,
          total: messagesHistories.total,
          page: messagesHistories.page,
        }
      ))


    } else {
      // console.log("không đang ở conversation mà tin nhắn gửi về")
    }

  } catch (error: any) {
    console.log("errr", error)
  }
}
function* onReceiveReaction({ payload: data }: any): Generator<any, void, any>  {
  try {
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    let messagesHistoriesNew = messagesHistories.items;

    messagesHistoriesNew[data?.messageId] = {
      ...messagesHistoriesNew[data?.messageId],
      reaction: data?.reaction,
    }
    yield put(chatsApiResponseSuccess(
      ChatsActionTypes.GET_MESSAGES_HISTORIES,
      {
        items: messagesHistoriesNew,
        total: messagesHistories.total,
        page: messagesHistories.page,
      }
    ))

  } catch (error: any) {
    console.log("errr", error)
  }
}

function* updateConversationSelect({ payload: data }: any): Generator<any, void, any>  {
  try {
    const conversationList = yield select(selectorsGetConversationList)
    let conversationListNew  = conversationList.items;
    const existingIndex = conversationListNew?.findIndex((item: any) => item?.conversationId === data?.conversationId);
    if (existingIndex !== -1) {
      conversationListNew?.splice(existingIndex, 1, data);
      yield put(changeSelectedConversation({
        ...data,
        lastSeen: data?.lastSeen || null,
      }));
    }
  } catch (error: any) {
    console.log("errr", error)
  }
}

function* receiveMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error));
  }
}




function* getChannelDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChannelDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHANNEL_DETAILS, error)
    );
  }
}

function* toggleFavouriteContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleFavouriteContactApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT, error)
    );
  }
}

function* getArchiveContact() {
  try {
    const response: Promise<any> = yield call(getArchiveContactApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVE_CONTACT, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_ARCHIVE_CONTACT, error)
    );
  }
}

function* toggleArchiveContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleArchiveContactApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, error)
    );
  }
}

function* onUpdateTranslationSuccess({ payload: data }: any): Generator<any, void, any>  {
  const translateReceivedTo = data?.translateReceivedTo;
  try {
    const messagesHistories = yield select(selectorsGetMessagesHistories);
    let messagesHistoriesHashMap = messagesHistories.items;
    const messagesHistoriesArray = Object.values(messagesHistoriesHashMap);

    let listMessageNotTransalte: any = [];
    messagesHistoriesArray.forEach((element: any) => {
      // Thêm bước message đó đã được dịch chưa
      if(element?.text && !element?.extraInfo?.translation?.translations?.find((item: any) => item.language === translateReceivedTo)) {
        listMessageNotTransalte.push({
          from: '',
          to: translateReceivedTo,
          messageId: element?.messageId,
          text: element?.text,
        })
      }
    });
    const extensionReady = yield select(selectorsExtensionReady)
    if(extensionReady ) {
      const selectedConversation = yield select(selectorsGetSelectedConversation);
      onTranslationListMessage(listMessageNotTransalte, selectedConversation?.conversationId);
    } else {
      toast.error("Vui lòng cài kiểm tra đã cài đủ 2 extension chưa")
    }

  } catch (error: any) {
   
  }
}

function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
    yield put(getDirectMessagesAction());
    yield put(getFavouritesAction());
    yield put(getChannelsAction());
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error));
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}

function* onTranslationMessageId({payload}: any): Generator<any, void, any>  {
  try {
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    let messagesHistoriesHashMap = messagesHistories.items;
    if (messagesHistoriesHashMap[payload?.messageId]) {
      let translations = messagesHistoriesHashMap[payload?.messageId].extraInfo?.translation?.translations || [];
      const isExist = translations.find((item: any) => item.language === payload?.to);
      if (!isExist) {
        translations.push({
          language: payload?.to,
          message: payload?.translate,
        });
        messagesHistoriesHashMap[payload?.messageId] = {
          ...messagesHistoriesHashMap[payload?.messageId],
          extraInfo: {
            translation: {
              translations: translations,
            },
          },
        };
      }
    }
    yield put(chatsApiResponseSuccess(
      ChatsActionTypes.GET_MESSAGES_HISTORIES,
      {
        items: messagesHistoriesHashMap,
        total: messagesHistories.total,
        page: messagesHistories.page,
        isGetMore: false,
      }
    ))
  } catch (error: any) {
  }
}


function* onSendMessageExtensionSuccess({payload}: any): Generator<any, void, any>  {
  // Khi sendMessage thành công qua extenstion thì phải update timestamp vào list message
  try {
    const selectedConversation = yield select(selectorsGetSelectedConversation)
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    console.log("messagesHistories", messagesHistories)
    if(selectedConversation?.pageId == payload?.request?.pageId) {
      let messagesHistoriesNew = messagesHistories?.items;
      messagesHistoriesNew[payload?.request?.messageIdExtention] = {
        ...messagesHistoriesNew[payload?.request?.messageIdExtention],
        timestamp: payload?.timestamp,
        globalUserId: payload?.globalUserId
      };
      yield put(chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          items: messagesHistoriesNew,
          total: messagesHistories.total,
          page: messagesHistories.page,
        }
      ))
    }
  } catch (error: any) {
    // yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}

export function* watchGetChannels() {
  yield takeEvery(ChatsActionTypes.GET_CHANNELS, getChannels);
}

export function* watchCreateChannel() {
  yield takeEvery(ChatsActionTypes.CREATE_CHANNEL, createChannel);
}
export function* watchGetChatUserDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
}
export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchGetMessagesHistories() {
  yield takeEvery(
    ChatsActionTypes.GET_MESSAGES_HISTORIES,
    getMessagesHistories
  );
}
export function* watchUpdateTagConversation() {
  yield takeEvery(
    ChatsActionTypes.UPDATE_TAG_CONVERSATION,
    updateTagConversation
  );
}
export function* watchGetConversationList() {
  yield takeEvery(
    ChatsActionTypes.GET_CONVERSATION_LIST,
    getConversationList
  );
}

export function* watchUpdateConversationList() {
  yield takeEvery(
    ChatsActionTypes.UPDATE_CONVERSATION_LIST,
    onReceiveMessage
  );
}

export function* watchUpdateTagsInConversation() {
  yield takeEvery(
    ChatsActionTypes.UPDATE_TAGS_IN_CONVERSATION,
    onUpdateTagsInConversation
  );
}

export function* watchUpdateConversationSelect() {
  yield takeEvery(
    ChatsActionTypes.UPDATE_CONVERSATION_SELECT,
    updateConversationSelect
  );
}

export function* watchReceiveReaction() {
  yield takeEvery(
    ChatsActionTypes.RECEIVE_REACTION,
    onReceiveReaction
  );
}
export function* watchOnSendMessageExtension() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE_BY_EXTENSION, onSendMessageExtensionSuccess);
}

export function* watchOnSendMessage(): Generator<any, void, any> {
  const requestChannel: ActionPattern = yield actionChannel(ChatsActionTypes.ON_SEND_MESSAGE); // Tạo hàng đợi
  while (true) {
    const action = yield take(requestChannel); // Lấy hành động từ hàng đợi
    yield call(onSendMessage, action); // Gọi tuần tự từng `onSendMessage`
  }
}

export function* watchOnSendMultiMsg() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MULTI_MESSAGE, onSendMultiMsg);
}
export function* watchReceiveMessage() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, receiveMessage);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}

export function* watchOnDeleteMessage() {
  yield takeEvery(ChatsActionTypes.ON_DELETE_MESSAGE, onDeleteMessage);
}

export function* watchGetChannelDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHANNEL_DETAILS, getChannelDetails);
}
export function* watchToggleFavouriteContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
    toggleFavouriteContact
  );
}
export function* watchGetArchiveContact() {
  yield takeEvery(ChatsActionTypes.GET_ARCHIVE_CONTACT, getArchiveContact);
}
export function* watchToggleArchiveContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
    toggleArchiveContact
  );
}
export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}

export function* watchOnTranslationMessageId() {
  yield takeEvery(ChatsActionTypes.ONTRANSLATIONMESSAGEID, onTranslationMessageId);
}

export function* watchOnUpdateTranslationSuccess() {
  yield takeEvery(ChatsActionTypes.ON_UPDATE_TRANSLATION_SUCCESS, onUpdateTranslationSuccess);
}

function* chatsSaga() {
  yield all([
    fork(watchGetChannels),
    fork(watchCreateChannel),
    fork(watchGetChatUserDetails),
    fork(watchGetChatUserConversations),
    fork(watchGetMessagesHistories),
    fork(watchUpdateTagConversation),
    fork(watchGetConversationList),
    fork(watchOnSendMessage),
    fork(watchOnSendMultiMsg),
    fork(watchOnDeleteMessage),
    fork(watchGetChannelDetails),
    fork(watchToggleFavouriteContact),
    fork(watchGetArchiveContact),
    fork(watchToggleArchiveContact),
    fork(watchReadConversation),
    fork(watchDeleteImage),
    fork(watchUpdateConversationList),
    fork(watchUpdateTagsInConversation),
    fork(watchUpdateConversationSelect),
    fork(watchReceiveReaction),
    fork(watchOnSendMessageExtension),
    fork(watchOnTranslationMessageId),
    fork(watchOnUpdateTranslationSuccess),
  ]);
}

export default chatsSaga;
