import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

//api
import {
    getPageListConnected,
    getPageSelected,
    syncTag,
} from "../../../api/index";

// component
import type { RadioChangeEvent } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    Tooltip,
    Modal,
    Row,
    Col,
    Dropdown,
    Input,
    Checkbox,
    Table,
    Radio,
    Progress,
    message,
} from 'antd';
import iconFb from "../../../assets/images/users/ic_fb.png";
import IconSync from "../../../assets/images/tag/sync.svg";
import IconPageTo from "../../../assets/images/tag/pageTo.svg";
import IconDown from "../../../assets/images/tag/down.svg";
import IconTick from "../../../assets/images/tag/tick.svg";
import IconSuccess from "../../../assets/images/tag/success.svg";
import {
    SearchOutlined,
    CloseOutlined,
    CheckOutlined,
} from '@ant-design/icons';

// styles
import styled from "styled-components";
import { hexToRgba } from "../../../utils/color";

interface ModalSyncProps {
    allTag: any;
    isOpen: boolean;
    onClose: () => void;
    channelId: string;
    className?: string;
}

const Index = ({ isOpen, onClose, channelId, allTag, className }: ModalSyncProps) => {
    const [channelSelected, setChannelSelected] = useState<any>(null);
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [channelIds, setChannelIds] = useState<any[]>([]);
    const [channelIdsSync, setChannelIdsSync] = useState<any[]>([]);
    const [valueSending, setValueSending] = useState<any>(null);
    const [allPage, setAllPage] = useState<any[]>([]);
    const [pageList, setPageList] = useState<any>(null);
    const [valueSearch, setValueSearch] = useState<string>('');
    const [tagIds, setTagIds] = useState<any[]>([]);
    const [listTag, setListTag] = useState<any[]>([]);
    const [valueInput, setValueInput] = useState<string>('');
    const [modeValue, setModeValue] = useState<string>('UPDATE_DUPLICATE');

    useEffect(() => {
        if (channelId && channelSelected === null) {
            getDetailChannel({
                channelIds: channelId,
            });
            setListTag(allTag);
        }
    }, [channelSelected]);

    useEffect(() => {
        if (pageList === null) {
            getPageListData({ valueSearch: valueSearch });
        }
    }, []);

    const getPageListData = async (data: any) => {
        try {
            const response: any = await getPageListConnected({ isAll: true, q: data?.valueSearch });
            if (response?.data?.items) {
                const arr = response.data.items.filter((page: any) => page?.id !== channelId);
                setPageList({
                    total: response.data.total,
                    page: data.page,
                    items: arr,
                });
                if (data?.valueSearch?.length == 0 && allPage?.length == 0) {
                    setAllPage(arr);
                }
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const getDetailChannel = async (data: any) => {
        try {
            const response: any = await getPageSelected({ channelIds: data?.channelIds });
            if (response?.data?.items?.length > 0) {
                setChannelSelected(response?.data?.items?.[0]);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const onChange = (e: any) => {
        if (e.target.checked) {
            setChannelIds(allPage?.map((item: any) => item.id));
            setValueSending(null);
            setChannelIdsSync([]);
        } else {
            setChannelIds([]);
            setValueSending(null);
            setChannelIdsSync([]);
        }
    };

    const onSelect = (id: string) => {
        const index = channelIds.indexOf(id);
        if (index === -1) {
            setChannelIds([...channelIds, id]);
            setValueSending(null);
            setChannelIdsSync([]);
        } else {
            setChannelIds(channelIds.filter((item: any) => item !== id));
            setValueSending(null);
            setChannelIdsSync([]);
        }
    };

    const searchPages = _.debounce((value: string) => {
        getPageListData({ valueSearch: value });
    }, 300);

    const onChangeTag = (e: any) => {
        if (e.target.checked) {
            setTagIds(allTag?.map((item: any) => item.id));
            setValueSending(null);
            setChannelIdsSync([]);
        } else {
            setTagIds([]);
            setValueSending(null);
            setChannelIdsSync([]);
        }
    };

    const onSelectTag = (e: any, id: string) => {
        if (e.target.checked) {
            setTagIds([...tagIds, id]);
            setValueSending(null);
            setChannelIdsSync([]);
        } else {
            setTagIds(tagIds.filter((item: any) => item !== id));
            setValueSending(null);
            setChannelIdsSync([]);
        }
    };

    const searchTags = (e: any) => {
        const value = e.target.value;
        setValueInput(value);
        if (value === '') {
            setListTag(allTag);
        } else {
            setListTag(allTag?.filter((item: any) => item.name.toLowerCase().includes(value.toLowerCase())));
        }
    };

    const renderDropdown = () => {
        return (
            <div
                className={className}
                style={{
                    width: 450,
                    borderRadius: 6,
                    backgroundColor: 'white',
                    padding: 6
                }}
            >
                <div style={{
                    padding: '8px 9px 5px 8px',
                }}>
                    <Input
                        placeholder="Nhập tên trang để tìm kiếm"
                        prefix={<SearchOutlined />}
                        onChange={(e) => {
                            setValueSearch(e.target.value);
                            searchPages(e.target.value);
                        }}
                        value={valueSearch}
                    />
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 8
                    }}>
                        <Checkbox
                            indeterminate={channelIds?.length > 0 && channelIds?.length < allPage?.length}
                            onChange={onChange}
                            checked={channelIds?.length === allPage?.length}
                        >Chọn tất cả các trang</Checkbox>
                        {
                            channelIds?.length > 0 ? (
                                <div style={{
                                    display: 'inline-block',
                                    color: localStorage.getItem('colorTheme') || '#2f54eb',
                                    backgroundColor:  hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3),
                                    borderRadius: 16,
                                    padding: '0 8px',
                                    fontWeight: '600'
                                }}>
                                    Đã chọn {channelIds?.length}
                                </div>
                            ) : null
                        }
                    </div>
                </div>
                <div style={{
                    overflow: 'auto',
                    height: 280,
                }}>
                    {
                        pageList?.items?.map((item: any, index: number) => {
                            if (!item.name) return null;
                            return (
                                <div
                                    key={index}
                                    className="option-item"
                                    style={{
                                        marginTop: index !== 0 ? 4 : 0,
                                        backgroundColor: channelIds.indexOf(item.id) !== -1 ? hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3) : ''
                                    }}
                                    onClick={() => onSelect(item?.id)}
                                >
                                    <div
                                        className="option-content"
                                        style={{
                                            justifyContent: 'space-between',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}>
                                            <img src={item?.picture} style={{ width: 28, height: 28, borderRadius: 4, marginRight: 10 }} />
                                            <div style={{ display: 'grid' }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                    <img
                                                        src={iconFb}
                                                        alt=""
                                                        style={{ width: 14, height: 14, marginRight: 8 }}
                                                    />
                                                    <span style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }}>{item?.name}</span>
                                                </div>
                                                <div style={{
                                                    fontSize: 12,
                                                    color: '#667085'
                                                }}>
                                                    {item?.id}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: 16,
                                                height: 16
                                            }}
                                        >
                                            {
                                                channelIds.indexOf(item.id) !== -1 ? (
                                                    <CheckOutlined style={{ width: 16, height: 16 , color: localStorage.getItem('colorTheme') || '#2f54eb'}}/>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    };

    const columns: ColumnsType<any> = [
        {
            key: 'sort',
            align: 'center',
            title: (
                <Checkbox
                    indeterminate={tagIds?.length > 0 && tagIds?.length < allTag?.length}
                    onChange={onChangeTag}
                    checked={tagIds?.length === allTag?.length}
                />
            ),
            width: 60,
            render: (record) => (
                <Checkbox
                    onChange={(e) => onSelectTag(e, record?.id)}
                    checked={tagIds?.includes(record?.id)}
                />
            )
        },
        {
            title: <span style={{ paddingLeft: 40 }}>Name</span>,
            width: 360,
            render: (record) => (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <span style={{ marginRight: 32 }}>{record?.sequence + 1}.</span>
                    <span>{record.name}</span>
                </div>
            ),
        },
        {
            title: 'Color',
            render: (record) => (
                <div
                    style={{
                        backgroundColor: record.color,
                        width: 180,
                        height: 22,
                        borderRadius: 16,
                    }}
                />
            ),
        },
    ];

    const onChangeMode = (e: RadioChangeEvent) => {
        setModeValue(e.target.value);
    };

    const onSubmit = async () => {
        try {
            const response: any = await syncTag({
                mode: modeValue,
                sourceChannelId: channelId,
                targetChannelIds: channelIds,
                onlyTagIds: tagIds
            });
            if (response?.data && response?.success) {
                message.success('Đồng bộ thẻ hoàn tất')
                setChannelIdsSync(channelIds);
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <Modal
            className={className}
            open={isOpen}
            onCancel={onClose}
            onOk={() => { }}
            closeIcon={null}
            maskClosable={false}
            footer={null}
            width={1010}
            centered
            styles={{
                content: {
                    padding: 0
                }
            }}
        >
            <Row
                style={{
                    alignItems: 'center',
                    padding: '16px 24px',
                    borderBottom: '1px solid #e8e8e8'
                }}>
                <Col span={12}>
                    <div style={{
                        fontSize: 18,
                        fontWeight: '500'
                    }}>Đồng bộ thẻ hội thoại</div>
                </Col>
                <Col span={12} style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <CloseOutlined style={{ fontSize: 20, cursor: 'pointer' }} onClick={onClose} />
                </Col>
            </Row>
            <div style={{
                backgroundColor: '#F2F4F7',
                padding: 16,
            }}>
                <Row
                    style={{
                        alignItems: 'center',
                    }}>
                    <Col span={11}>
                        <div className="channel-item">
                            <div
                                className="channel-content"
                            >
                                <img
                                    src={channelSelected?.picture}
                                    alt=""
                                    style={{
                                        width: 60,
                                        height: 60,
                                        marginRight: 20,
                                        borderRadius: '50%',
                                        border: '2px solid transparent',
                                    }}
                                />
                                <div style={{ display: 'grid' }}>
                                    <div
                                        style={{
                                            fontSize: 12,
                                            fontWeight: '500',
                                            color: '#667085'
                                        }}>
                                        Trang nguồn
                                    </div>
                                    <Tooltip title={channelSelected?.name}>
                                        <div
                                            style={{
                                                fontSize: 16,
                                                margin: '3px 0',
                                                fontWeight: '500',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis'
                                            }}>
                                            {channelSelected?.name}
                                        </div>
                                    </Tooltip>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={iconFb}
                                            alt=""
                                            style={{ width: 14, height: 14, marginRight: 4 }}
                                        />
                                        <span style={{ fontSize: 12, color: '#667085' }}>{channelSelected?.id}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        span={2}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src={IconSync} style={{ width: 32, height: 32 }} />
                    </Col>
                    <Col span={11}>
                        <Dropdown
                            className={className}
                            open={isOpenDropdown}
                            trigger={['click']}
                            placement="bottom"
                            dropdownRender={() => renderDropdown()}
                            onOpenChange={(open) => setIsOpenDropdown(open)}
                        >
                            <div className="channel-item">
                                <div
                                    className="channel-content"
                                    style={{
                                        justifyContent: 'space-between',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        {
                                            channelIds?.length > 0 ? (
                                                <>
                                                    <div style={{
                                                        display: 'flex',
                                                        position: 'relative',
                                                        marginRight: 20,
                                                    }}>
                                                        {
                                                            allPage?.filter((page) => channelIds.includes(page?.id))?.slice(0, 3).map((page, index) => (
                                                                <img
                                                                    key={index}
                                                                    src={page?.picture}
                                                                    alt=""
                                                                    style={{
                                                                        width: 60,
                                                                        height: 60,
                                                                        borderRadius: '50%',
                                                                        border: '2px solid white',
                                                                        marginLeft: index == 0 ? 0 : -23,
                                                                    }}
                                                                />
                                                            ))
                                                        }
                                                        {
                                                            allPage?.filter((page) => channelIds.includes(page?.id))?.length > 3 ? (
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                    width: 60,
                                                                    height: 60,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#0000002a',
                                                                }}>
                                                                    <span style={{ color: 'white', fontSize: 16, fontWeight: '600' }}>{allPage?.filter((page) => channelIds.includes(page?.id))?.length - 3}+</span>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: '500',
                                                                color: '#667085'
                                                            }}>
                                                            Trang đích
                                                        </div>
                                                        <Tooltip title={
                                                            channelIds?.length === 1 ?
                                                                allPage?.find((page) => channelIds.includes(page?.id))?.name :
                                                                `
                                                                    ${allPage?.filter((page) => channelIds.includes(page?.id))[0]?.name
                                                                    } và ${allPage?.filter((page) => channelIds.includes(page?.id))?.length - 1
                                                                    } trang khác
                                                                `
                                                        }>
                                                            <div
                                                                style={{
                                                                    fontSize: 16,
                                                                    margin: '3px 0',
                                                                    fontWeight: '500',
                                                                    overflow: 'hidden',
                                                                    whiteSpace: 'nowrap',
                                                                    textOverflow: 'ellipsis'
                                                                }}>
                                                                {
                                                                    channelIds?.length === 1 ?
                                                                        allPage?.find((page) => channelIds.includes(page?.id))?.name :
                                                                        `
                                                                            ${allPage?.filter((page) => channelIds.includes(page?.id))[0]?.name
                                                                            } và ${allPage?.filter((page) => channelIds.includes(page?.id))?.length - 1
                                                                            } trang khác
                                                                        `
                                                                }
                                                            </div>
                                                        </Tooltip>
                                                        <div style={{ height: 19 }} />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div
                                                        style={{
                                                            width: 60,
                                                            height: 60,
                                                            marginRight: 20,
                                                            backgroundColor: '#F2F4F7',
                                                            borderRadius: '50%',
                                                            border: '2px solid transparent',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <img src={IconPageTo} style={{ width: 24, height: 24 }} />
                                                    </div>
                                                    <div style={{ display: 'grid' }}>
                                                        <div
                                                            style={{
                                                                fontSize: 12,
                                                                fontWeight: '500',
                                                                color: '#667085'
                                                            }}>
                                                            Trang đích
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: 16,
                                                                margin: '3px 0',
                                                                fontWeight: '500',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                textOverflow: 'ellipsis'
                                                            }}>
                                                            Chưa chọn trang
                                                        </div>
                                                        <div style={{ height: 19 }} />
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: 3
                                        }}
                                    >
                                        <img src={IconDown} style={{ width: 18, height: 18 }} />
                                    </div>
                                </div>
                            </div>
                        </Dropdown>
                    </Col>
                </Row>
                <div className="list-tag">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: 16
                    }}>
                        <div className="search-tag">
                            <Input
                                placeholder="Tìm kiếm"
                                prefix={<SearchOutlined />}
                                onChange={searchTags}
                                value={valueInput}
                            />
                        </div>
                        {
                            tagIds?.length > 0 ? (
                                <div style={{
                                    display: 'inline-block',
                                    color: localStorage.getItem('colorTheme') || '#2f54eb',
                                    backgroundColor:  hexToRgba(localStorage.getItem('colorTheme') || '#2f54eb', 0.3),
                                    borderRadius: 16,
                                    padding: '0 8px',
                                    fontWeight: '500'
                                }}>
                                    Đã chọn {tagIds?.length}
                                </div>
                            ) : null
                        }
                    </div>
                    <div
                        className="bg-white"
                        style={{
                            borderRadius: 8,
                            marginTop: 16,
                            paddingBottom: 4,
                            border: '1px solid #d0d0d0'
                        }}>
                        {
                            listTag?.length > 0 ? (
                                <Table
                                    rowKey="key"
                                    pagination={false}
                                    columns={columns}
                                    scroll={{ y: 280 }}
                                    dataSource={
                                        listTag.map((tag: any) => ({
                                            key: tag.id,
                                            ...tag,
                                        }))
                                    }
                                />
                            ) : null
                        }
                    </div>
                </div>
                {
                    valueSending ? (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 0'
                        }}>
                            <Progress
                                percent={channelIds?.length === channelIdsSync?.length ? 100 : valueSending.percent}
                                showInfo={false}
                                type="line"
                                strokeColor={'#52c41a'}
                            />
                            <span style={{
                                marginLeft: 8,
                                whiteSpace: 'nowrap'
                            }}>
                                {
                                    channelIds?.length === channelIdsSync?.length ?
                                        'Hoàn thành' :
                                        `${valueSending.number}/${channelIds?.length}`
                                }
                            </span>
                        </div>
                    ) : null
                }
                {
                    channelIdsSync?.length > 0 ? (
                        <div style={{
                            padding: 16,
                            backgroundColor: 'white',
                            borderRadius: 12
                        }}>
                            <div style={{
                                maxHeight: 190,
                                overflow: 'auto'
                            }}>
                                {
                                    allPage?.filter((page) => channelIdsSync.includes(page?.id))?.map((item, index) => (
                                        <div className="item-page-success" style={{
                                            marginTop: index == 0 ? 0 : 4
                                        }}>
                                            <img src={IconSuccess} style={{ width: 24, height: 24 }} />
                                            <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >
                                                <div
                                                    style={{
                                                        fontWeight: '500',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >{item?.name}</div>
                                                <div>Đồng bộ thẻ hội thoại thành công</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ) : null
                }
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px 16px',
                    borderTop: '1px solid #e8e8e8',
                    borderRadius: '0 0 12px 12px',
                }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <div style={{
                        padding: '5px 8px',
                        marginRight: 10,
                    }}>
                        <Radio onChange={onChangeMode} value={'UPDATE_DUPLICATE'} checked={modeValue === 'UPDATE_DUPLICATE'}>
                            <span style={{ fontSize: 16, fontWeight: '500' }}>Thêm vào danh sách hiện có</span>
                        </Radio>
                    </div>
                    <div style={{
                        padding: '5px 8px',
                    }}>
                        <Radio onChange={onChangeMode} value={'REPLACE_ALL'} checked={modeValue === 'REPLACE_ALL'}>
                            <span style={{ fontSize: 16, fontWeight: '500' }}>Thay thế danh sách hiện có</span>
                        </Radio>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <div
                        className="btn-close-sync"
                        onClick={onClose}
                    >
                        Đóng
                    </div>
                    <div
                        className="btn-submit-sync"
                        style={{
                            backgroundColor: channelIds?.length > 0 && tagIds?.length > 0 ? localStorage.getItem('colorTheme') || '#2f54eb' : '#8596B4',
                            cursor: channelIds?.length > 0 && tagIds?.length > 0 ? 'pointer' : 'no-drop'
                        }}
                        onClick={() => {
                            setValueSending({
                                number: channelIds?.length === 1 ? 0 : Math.round(0.30 * channelIds?.length),
                                percent: 30,
                            });
                            setChannelIdsSync([]);
                            onSubmit();
                        }}
                    >
                        Sao chép
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default styled(Index)`
    .channel-item {
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 8px;
        background-color: white;
    }
    .cancel-channel {
        display: none;
        position: absolute;
        right: 8px;
        top: 8px;
    }
    .channel-content {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .option-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 8px;
        border-radius: 4px;
        &:hover {
            background-color: #f2f4f7;
        }
    }
    .option-content {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 16px;
    }
    .list-tag {
        padding: 16px;
        background-color: #fff;
        border-radius: 8px;
        margin-top: 8px;
    }
    .search-tag {
        width: 250px;
    }
    .btn-close-sync {
        border-radius: 6px;
        background-color: #F2F4F7;
        color: #000;
        font-weight: 500;
        border: none;
        height: 32px;
        padding: 0 15px;
        font-size: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            background-color: #E2E6EE;
        }
    }
    .btn-submit-sync {
        display: flex;
        align-items: center;
        margin-left: 8px;
        border-radius: 6px;
        color: #fff;
        font-weight: 500;
        border: none;
        height: 32px;
        padding: 0 15px;
        font-size: 14px;
    }
    .item-page-success {
        border-radius: 8px;
        padding: 8px;
        color: #1d2939;
        display: flex;
        gap: 8px;
        border: 1px solid #dde1e7;
    }
`
