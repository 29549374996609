import { format } from "date-fns";

const formateDate = (date: any, formate?: string) => {
  const dateObj = new Date(date);
  const dateFormat = formate ? formate : "MM/dd/yyyy";
  const formattedDate = format(dateObj, dateFormat);
  return formattedDate;
};



function isWithin24Hours(dateString: any) {
  // Kiểm tra đầu vào có hợp lệ hay không
  if (!Date.parse(dateString)) {
    throw new Error("Invalid date format");
  }
  
  const now: any = new Date(); // Thời điểm hiện tại
  const inputDate: any = new Date(dateString); // Chuyển chuỗi thành đối tượng Date
  
  // Tính chênh lệch thời gian bằng milliseconds
  const diffInMs = now - inputDate;
  
  // Nếu thời gian chênh lệch âm, tức là dateString là thời điểm trong tương lai
  if (diffInMs < 0) {
    return false;
  }
  
  // Chuyển đổi milliseconds thành giờ
  const diffInHours = diffInMs / (1000 * 60 * 60);
  
  // Kiểm tra nếu chênh lệch <= 24 giờ
  return diffInHours <= 24;
}

export { formateDate, isWithin24Hours };
